import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { Link } from 'Components/Buttons/Buttons';
import { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { Page } from 'Components/Page/Page';
import Placeholder from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { CREATE, RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment, RiskCategoryResponse, RiskResponse } from 'Models/RiskRegister';

import { AssessmentListing } from './AssessmentListing/AssessmentListing';
import { RiskPeriodComparison } from './RiskPeriodComparison/RiskPeriodComparison';

enum RiskAssessmentListingTabs {
    ASSESSMENTS = 'assessments',
    RISK_PERIOD_TREND = 'risk_period_trend',
}

export interface RiskListingProps {
    riskRegisterApi: RiskRegisterApi;
}

export const ALL_RISKS = 'All Risks';

export const RiskAssessmentListing = (props: RiskListingProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [riskCategories, setRiskCategories] = useState<RiskCategoryResponse[]>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [riskAssessments, setRiskAssessments] = useState<RiskAssessment[]>();
    const [selectedRiskCategoryId, setSelectedRiskCategoryId] = useState<string>(ALL_RISKS);

    useEffect(() => {
        const getAssessments = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskAssessment[]> = await props.riskRegisterApi.getAllRiskAssessments();
                setRiskAssessments(riskResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskResponse[]> = await props.riskRegisterApi.getAllRisks();
                setRisks(riskResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const getRiskCategories = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setRiskCategories([{ title: ALL_RISKS, id: ALL_RISKS, in_use: true }, ...response.data]);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        getRisks();
        getAssessments();
        getRiskCategories();
    }, [props.riskRegisterApi]);

    const handleSelectChange = (value: ChangeEventType): void => {
        setSelectedRiskCategoryId(value as string);
    };

    const onTabSelect = (key?: string) => {
        if (key) {
            navigate(`${location.pathname}#${key}`, { replace: true });
        }
    };

    if (risks && riskCategories && riskAssessments) {
        return (
            <Page
                headerTitle="Risk Reviews"
                headerButtons={
                    <Link variant="primaryButton" to={`/${RISK_REGISTER}/${RISK_REVIEW}/${CREATE}`} fontAwesomeImage={faFileSignature}>
                        Create Review
                    </Link>
                }
                body={[
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab={RiskAssessmentListingTabs.ASSESSMENTS} onSelect={onTabSelect} removePadding>
                                <Tab eventKey={RiskAssessmentListingTabs.ASSESSMENTS} title="Review Listing" unmountOnExit={true}>
                                    <AssessmentListing riskAssessments={riskAssessments} categories={riskCategories} handleRiskCategoryChange={handleSelectChange} selectedRiskCategory={selectedRiskCategoryId} />
                                </Tab>
                                <Tab eventKey={RiskAssessmentListingTabs.RISK_PERIOD_TREND} title="Risk Period Comparison" unmountOnExit={true}>
                                    <RiskPeriodComparison categorySelection={{ categories: riskCategories, handleRiskCategoryChange: handleSelectChange }} riskAssessments={riskAssessments} risks={risks} selectedRiskCategory={selectedRiskCategoryId} />
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};
