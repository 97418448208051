import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import GovernanceLibraryTable, { GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

export interface ActiveGovernanceTabProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItems: GovernanceVersion[];
    users: UserResponse[];
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    openExternalUrl: (url: string) => void;
}

const ActiveGovernanceTab = (props: ActiveGovernanceTabProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        users: props.users,
        governanceItems: props.governanceItems,
        openExternalUrl: props.openExternalUrl,
        governanceItemsAreManageable: true,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
    };

    return <GovernanceLibraryTable {...governanceListingTableProps} />;
};

export default ActiveGovernanceTab;
