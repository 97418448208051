import { faEdit, faFolder, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Card } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import Text from 'Components/Text/Text';
import { ISSUES_EXCEPTIONS, SERVICES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { VendorResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';
import { ManageTPRMServicesRouteState } from 'Pages/TPRM/ManageTPRMServices/ManageTPRMServices';

import styles from './VendorCard.module.css';
import { VendorListingServiceListing } from '../../VendorListingServiceListing/VendorListingServiceListing';

export interface VendorCardProps {
    vendor: VendorResponseWithServices;
    selectedDeleteVendor: (vendorId: string) => void;
    selectedModifyVendor: (vendorId: string) => void;
    selectedCreateServiceForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewFolders: (vendorId: string) => void;
    users: UserResponse[];
}

export default class VendorCard extends Component<VendorCardProps> {
    render(): JSX.Element {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Create a Service',
                    onClickAction: () => this.props.selectedCreateServiceForVendor(this.props.vendor),
                    icon: faPlus,
                },
                {
                    text: 'Update Vendor',
                    onClickAction: () => this.props.selectedModifyVendor(this.props.vendor.id),
                    icon: faEdit,
                },
                {
                    text: 'Manage Documents',
                    onClickAction: () => this.props.selectedViewFolders(this.props.vendor.id),
                    icon: faFolder,
                },
                {
                    text: 'Delete Vendor',
                    onClickAction: () => this.props.selectedDeleteVendor(this.props.vendor.id),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${this.props.vendor.name} Menu`,
        };

        const hasServices = this.props.vendor.services.length > 0;
        const state: ManageTPRMServicesRouteState | undefined = hasServices ? undefined : { createServiceForVendor: this.props.vendor };
        const numberOfOpenIssues = countOpenIssues(this.props.vendor.issues);
        const numberOfOpenExceptions = countOpenExceptions(this.props.vendor.exceptions);

        return (
            <Card className={styles.card}>
                <div className={styles.cardHeader}>
                    <Text variant="Header2" noStyles>
                        {this.props.vendor.name}
                    </Text>
                    <div className={styles.overflowMenu}>
                        <OverflowMenu {...overflowMenuProps} />
                    </div>
                </div>
                <div className={styles.cardHeader}>
                    <Text variant="Text4" color="darkGray" noStyles toUppercase>
                        {this.props.vendor.website}
                    </Text>
                </div>
                <div className={styles.horizontalLine} />
                <div className={styles.cardSection}>
                    <Text variant="Text4" color="darkGray" noStyles toUppercase>
                        {hasServices ? `Services (${this.props.vendor.services.length} total)` : 'No services created yet'}
                    </Text>
                    <VendorListingServiceListing vendorId={this.props.vendor.id} services={this.props.vendor.services} />
                </div>
                <div className={styles.horizontalLinePushDown} />
                <div className={styles.cardSection}>
                    <div className={styles.row}>
                        <div>
                            <div>
                                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                    Date Created
                                </Text>
                            </div>
                            <Text variant="Text3" noStyles>
                                {iso8601ToUsDateLong(this.props.vendor.created_time)}
                            </Text>
                        </div>
                        <div>
                            <div>
                                <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                    Vendor Manager
                                </Text>
                            </div>
                            <Text variant="Text3" noStyles>
                                {getUserNameFromSubject(this.props.vendor.vendor_manager_user_id, this.props.users, UserNameFormat.FIRST_SPACE_LAST)}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontalLine} />
                <div className={styles.cardSection}>
                    <div className={styles.row}>
                        <div>
                            <Text variant="Text4" color="darkGray" noStyles toUppercase>
                                Issues & Exceptions
                            </Text>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${this.props.vendor.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                        </div>
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${this.props.vendor.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontalLine} />
                <div className={styles.cardFooter}>
                    <Link
                        size="lg"
                        to={{
                            pathname: `/${TPRM}/${SERVICES}`,
                            search: hasServices ? `vendorId=${this.props.vendor.id}` : '',
                        }}
                        state={state}
                    >
                        {hasServices ? 'VIEW SERVICES' : 'CREATE A SERVICE'}
                    </Link>
                </div>
            </Card>
        );
    }
}
