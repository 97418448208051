import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { useUsers } from 'Components/Context/UsersContext';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { ControlGroupTargetEffectivenessTabProps, TargetEffectivenessTab, TargetEffectivenessTabType } from 'Components/OperationalControls/TargetEffectivenessTab/TargetEffectivenessTab';
import { Page } from 'Components/Page/Page';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { LimitResponse } from 'Models/Limits';
import { DetailedControlGroupResponse } from 'Models/OperationalControls';

import { ControlGroupAssessmentTab, ControlGroupAssessmentTabProps } from './ControlGroupAssessmentTab/ControlGroupAssessmentTab';
import { ControlGroupConfigurationTab, ControlGroupConfigurationTabProps } from './ControlGroupConfigurationTab/ControlGroupConfigurationTab';
import { ControlGroupLimitsTab, ControlGroupLimitsTabProps } from './ControlGroupLimitsTab/ControlGroupLimitsTab';

export interface PathParams {
    controlFramework: string;
    controlGroupId: string;
}

export interface ControlGroupSettingsProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export interface ControlGroupDeleted {
    controlGroupIsDeleted?: boolean;
}

export const ControlGroupSettings = (props: ControlGroupSettingsProps) => {
    const [isGroupBreadcrumbEnabled, setIsGroupBreadcrumbEnabled] = useState(true);
    const [detailedControlGroupResponse, setDetailedControlGroupResponse] = useState<DetailedControlGroupResponse>();
    const [limitResponseList, setLimitResponseList] = useState<LimitResponse[]>();
    const [controlGroupIsDeleted, setControlGroupIsDeleted] = useState(false);
    const { users } = useUsers();
    const { controlFramework, controlGroupId } = useParams<keyof PathParams>() as PathParams;

    const getControlGroupConfiguration = useCallback(async (): Promise<void> => {
        try {
            const controlGroupConfigurationResponse = await props.controlsApi.getControlGroupConfiguration(controlFramework, controlGroupId);
            if (controlGroupConfigurationResponse) {
                const controlGroupConfiguration = controlGroupConfigurationResponse.data;
                setDetailedControlGroupResponse(controlGroupConfiguration);
            }
        } catch (err) {
            handleRequestError(err);
        }
    }, [controlFramework, controlGroupId, props.controlsApi]);

    const getControlGroupLimits = useCallback(async (): Promise<void> => {
        try {
            const entity_id = `${controlFramework}#${controlGroupId}`;
            const controlGroupLimitsResponse = await props.alertsLimitsApi.getControlLimits(entity_id);
            const controlGroupLimits = controlGroupLimitsResponse.data;
            setLimitResponseList(controlGroupLimits);
        } catch (error) {
            handleRequestError(error);
        }
    }, [controlFramework, controlGroupId, props.alertsLimitsApi]);

    useEffect(() => {
        getControlGroupConfiguration();
        getControlGroupLimits();
    }, [getControlGroupConfiguration, getControlGroupLimits]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    const onControlGroupDeleted = (): void => {
        setIsGroupBreadcrumbEnabled(false);
        setControlGroupIsDeleted(true);
    };

    if (users && detailedControlGroupResponse && limitResponseList) {
        const ControlGroupAssessmentTabProps: ControlGroupAssessmentTabProps & ControlGroupDeleted = {
            controlsApi: props.controlsApi,
            users: users,
            detailedControlGroupResponse: detailedControlGroupResponse,
            controlGroupIsDeleted: controlGroupIsDeleted,
        };

        const controlGroupLimitsTabProps: ControlGroupLimitsTabProps & ControlGroupDeleted = {
            alertsLimitsApi: props.alertsLimitsApi,
            detailedControlGroupResponse: detailedControlGroupResponse,
            getControlGroupLimits: getControlGroupLimits,
            limitResponseList: limitResponseList,
            controlGroupIsDeleted: controlGroupIsDeleted,
        };

        const controlGroupConfigurationTabProps: ControlGroupConfigurationTabProps & ControlGroupDeleted = {
            onControlGroupSaved: getControlGroupConfiguration,
            onControlGroupDeleted: onControlGroupDeleted,
            controlsApi: props.controlsApi,
            detailedControlGroupResponse: detailedControlGroupResponse,
            controlGroupIsDeleted: controlGroupIsDeleted,
        };

        const controlGroupEffectivenessTargetTabProps: ControlGroupTargetEffectivenessTabProps = {
            _type: TargetEffectivenessTabType.CONTROL_GROUP,
            controlsApi: props.controlsApi,
            detailedControlGroupResponse: detailedControlGroupResponse,
            targetEffectivenessUpdated: getControlGroupConfiguration,
            linkOnSuccess: `/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${detailedControlGroupResponse.control_framework}/${GROUPS}/${detailedControlGroupResponse.control_group_id}`,
            isDeleted: controlGroupIsDeleted,
        };

        const defaultActiveTab = detailedControlGroupResponse.is_custom ? 'configuration' : 'assessment';

        return (
            <Page
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={getFrameworkGroupControlURL(detailedControlGroupResponse.control_framework)}>{detailedControlGroupResponse.control_framework}</BreadcrumbLink>
                        {isGroupBreadcrumbEnabled ? <BreadcrumbLink link={getFrameworkGroupControlURL(`${detailedControlGroupResponse.control_framework}#${detailedControlGroupResponse.control_group_id}`)}>{detailedControlGroupResponse.control_group_name}</BreadcrumbLink> : <BreadcrumbText>{detailedControlGroupResponse.control_group_name}</BreadcrumbText>}
                        <BreadcrumbText>Settings</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={detailedControlGroupResponse.is_custom ? `${detailedControlGroupResponse.control_framework}: ${detailedControlGroupResponse.control_group_name} Settings` : `${detailedControlGroupResponse.control_framework}: ${detailedControlGroupResponse.control_group_id} Settings`}
                headerDescription={detailedControlGroupResponse.control_group_description}
                body={[
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab={defaultActiveTab} removePadding>
                                {detailedControlGroupResponse.is_custom ? (
                                    <Tab eventKey="configuration" title="Configuration">
                                        <ControlGroupConfigurationTab {...controlGroupConfigurationTabProps} />
                                    </Tab>
                                ) : (
                                    <></>
                                )}
                                <Tab title="Assessment" eventKey="assessment">
                                    <ControlGroupAssessmentTab {...ControlGroupAssessmentTabProps} />
                                </Tab>
                                <Tab title="Limits" eventKey="limits">
                                    <ControlGroupLimitsTab {...controlGroupLimitsTabProps} />
                                </Tab>
                                <Tab eventKey="target" title="Target">
                                    <TargetEffectivenessTab {...controlGroupEffectivenessTargetTabProps} />
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        );
    }

    return null;
};
