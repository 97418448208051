import axios, { AxiosInstance } from 'axios';

import AWSConfig from 'Config/AWSConfig';
import { LOGOUT } from 'Config/Paths';

import { AuthApi } from './AuthApi';

export class AWSAuthApi implements AuthApi {
    axiosInstance: AxiosInstance;
    constructor() {
        this.axiosInstance = axios.create();
        this.axiosInstance.defaults.baseURL = AWSConfig.apiGateway.URL;
    }

    login = async (code: string): Promise<string> => {
        const response = await this.axiosInstance.get('/login', { params: { code: code } });
        return response.headers['x-hps-token']!;
    };

    logout = (accessToken: string): Promise<void> => {
        const headers = { Authorization: `Bearer ${accessToken}` };

        return this.axiosInstance.delete(`/${LOGOUT}`, { headers: headers });
    };
}
