import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AWSDocumentApi } from 'Api/Document/AWSDocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import UserFilter from 'Components/Filters/UserFilter/UserFilter';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { CONFIGURATION, FOLDERS, TPRM, VENDORS } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { FolderTypes, FolderVersion, VendorResponse } from 'Models/TPRM';
import { Filter, Filters, OptionType } from 'Models/Types/GlobalType';

import styles from './FolderListing.module.css';
import { FoldersTable, FoldersTableProps } from '../FoldersTable/FoldersTable';

export interface FolderListingProps {
    tprmApi: TPRMApi;
    documentApi: AWSDocumentApi;
}

interface UrlParams {
    vendor_id: string;
}

export const FolderListing = (props: FolderListingProps) => {
    const { vendor_id } = useParams<keyof UrlParams>() as UrlParams;
    const [vendorResponse, setVendorResponse] = useState<VendorResponse>();
    const [folders, setFolders] = useState<FolderVersion[]>();
    const [sortedAndFilteredFolders, setSortedAndFilteredFolders] = useState<FolderVersion[]>();
    const [folderTypes, setFolderTypes] = useState<FolderTypes>();
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>({});
    const { users } = useUsers();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const getVendorDetails = async (): Promise<void> => {
            try {
                const detailedVendorResponse = await props.tprmApi.getVendorDetails(vendor_id);
                setVendorResponse(detailedVendorResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getVendorDetails();
    }, [props.tprmApi, vendor_id]);

    useEffect(() => {
        const getFolderTypes = async (): Promise<void> => {
            try {
                const folderTypeResponse: ResponseModel<FolderTypes> = await props.tprmApi.getAllFolderTypes();
                setFolderTypes(folderTypeResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getFolderTypes();
    }, [props.tprmApi]);

    useEffect(() => {
        const getFolders = async (): Promise<void> => {
            try {
                const foldersResponse: ResponseModel<FolderVersion[]> = await props.tprmApi.getFolders(vendor_id);
                setFolders(foldersResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getFolders();
    }, [props.tprmApi, vendor_id]);

    useEffect(() => {
        if (!folders) {
            return;
        }

        let foldersCopy = [...folders];

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                foldersCopy = foldersCopy.filter((folder) => {
                    return filter.value.includes(folder[filter.key]);
                });
            }
        }

        setSortedAndFilteredFolders(foldersCopy);
    }, [folders, users, selectedFilterOptions]);

    const getFoldersTableProps = (folders: FolderVersion[]) => {
        const foldersTableProps: FoldersTableProps = {
            folders: folders,
            users: users,
            documentApi: props.documentApi,
        };
        return foldersTableProps;
    };

    const createFolderTypeOptions = (): OptionType[] => {
        const folderTypeSelectOptions: OptionType[] = [];
        folderTypes?.type_set.forEach((type) => {
            folderTypeSelectOptions.push({ value: type, label: type });
        });
        return folderTypeSelectOptions;
    };

    const handleTypeSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        const filters: Filter = {
            key: 'type',
            value: value ? (value as string[]) : [],
        };
        setCurrentFilterSelection(filters);
    };

    const setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    if (sortedAndFilteredFolders && users && folderTypes && vendorResponse) {
        return (
            <>
                <Page
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${TPRM}/${VENDORS}`}>{vendorResponse.name}</BreadcrumbLink>
                            <BreadcrumbText>Vendor Documents</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle="Vendor Documents"
                    headerButtons={
                        <Link variant="primaryButton" to={`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${CONFIGURATION}`} fontAwesomeImage={faAdd}>
                            Add new folder
                        </Link>
                    }
                    body={[
                        {
                            content: (
                                <>
                                    <div className={styles.formFieldsContainer}>
                                        <div className={styles.formField}>
                                            <FormFieldSelect selectMultiple formFieldLabel="Filter by Folder Type" formFieldId="type" options={createFolderTypeOptions()} handleChange={handleTypeSelectChange} />
                                        </div>
                                        <div className={styles.formField}>
                                            <UserFilter filterLabel="Filter by Updated By" onUsersSelected={setCurrentFilterSelection} users={users} userFilterId="last_updated_by" formFieldId="updatedByFilter" />
                                        </div>
                                    </div>
                                    <FoldersTable {...getFoldersTableProps(sortedAndFilteredFolders)} />
                                </>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};
