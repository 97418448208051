import { Component, Fragment } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import DeleteLimitModal, { DeleteLimitModalProps } from 'Components/Limits/DeleteLimitModal/DeleteLimitModal';
import ModifyEffectivenessLimitModal, { ModifyEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/ModifyEffectivenessLimitModal/ModifyEffectivenessLimitModal';
import { LimitsTables, LimitsTablesProps } from 'Components/Limits/LimitsTables/LimitsTables';
import ModifyMetricLimitModal, { ModifyMetricLimitModalProps } from 'Components/Limits/Metric/ModifyMetricLimitModal/ModifyMetricLimitModal';
import { EffectivenessLimit, Limit, LimitResponse, LimitType, MetricLimit } from 'Models/Limits';

export interface ManageLimitsTabProps {
    limitResponseList: LimitResponse[];
    alertsLimitsApi: AlertsLimitsApi;
    updateLimitListing: () => void;
}
interface ManageLimitsTabState {
    deleteLimitId?: string;
    deleteLimitTitle?: string;
    displayedModal: Modals;
    modifyLimit?: Limit;
}
export enum Modals {
    DeleteLimitModal,
    ModifyEffectivenessLimitModal,
    ModifyMetricLimitModal,
    None,
}

export default class ManageLimitsTab extends Component<ManageLimitsTabProps, ManageLimitsTabState> {
    constructor(props: ManageLimitsTabProps) {
        super(props);
        this.state = { displayedModal: Modals.None };
    }

    displayDeleteLimitModal = (deleteLimitId: string, deleteLimitTitle: string): void => {
        this.setState({ deleteLimitId, deleteLimitTitle });
        this.displayModal(Modals.DeleteLimitModal);
    };

    displayModifyLimitModal = (modifyLimit: Limit): void => {
        this.setState({ modifyLimit });
        if (modifyLimit._type === LimitType.EFFECTIVENESS) {
            this.displayModal(Modals.ModifyEffectivenessLimitModal);
        } else if (modifyLimit._type === LimitType.METRIC) {
            this.displayModal(Modals.ModifyMetricLimitModal);
        }
    };

    displayModal = (modal: Modals): void => {
        this.setState({ displayedModal: modal });
    };

    render(): JSX.Element {
        const limitsTableProps: LimitsTablesProps = {
            limitResponseList: this.props.limitResponseList,
            displayDeleteLimitModal: this.displayDeleteLimitModal,
            displayModifyLimitModal: this.displayModifyLimitModal,
            alertsLimitsApi: this.props.alertsLimitsApi,
        };
        const deleteLimitModalProps: DeleteLimitModalProps = {
            alertsLimitsApi: this.props.alertsLimitsApi,
            deleteLimitId: this.state.deleteLimitId!,
            deleteLimitTitle: this.state.deleteLimitTitle!,
            hideModal: () => this.displayModal(Modals.None),
            updateLimitListing: this.props.updateLimitListing,
        };
        const modifyEffectivenessLimitModalProps: ModifyEffectivenessLimitModalProps = {
            alertsLimitsApi: this.props.alertsLimitsApi,
            hideModal: () => this.displayModal(Modals.None),
            limit: this.state.modifyLimit! as EffectivenessLimit,
            onLimitModified: this.props.updateLimitListing,
        };
        const modifyMetricLimitModalProps: ModifyMetricLimitModalProps = {
            alertsLimitsApi: this.props.alertsLimitsApi,
            hideModal: () => this.displayModal(Modals.None),
            metricLimit: this.state.modifyLimit! as MetricLimit,
            onLimitModified: this.props.updateLimitListing,
        };

        return (
            <Fragment>
                {this.state.displayedModal === Modals.DeleteLimitModal && <DeleteLimitModal {...deleteLimitModalProps} />}
                {this.state.displayedModal === Modals.ModifyEffectivenessLimitModal && <ModifyEffectivenessLimitModal {...modifyEffectivenessLimitModalProps} />}
                {this.state.displayedModal === Modals.ModifyMetricLimitModal && <ModifyMetricLimitModal {...modifyMetricLimitModalProps} />}
                <LimitsTables {...limitsTableProps} />
            </Fragment>
        );
    }
}
