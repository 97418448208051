import { useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import SortableTableHeader, { SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody } from 'Components/Table/Table/Table';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { GovernanceSortFilterOptions, GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

import GovernanceLibraryTableRow, { GovernanceLibraryTableRowProps } from './GovernanceLibraryTableRow/GovernanceLibraryTableRow';

export interface GovernanceLibraryTableProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    users: UserResponse[];
    governanceItems: GovernanceVersion[];
    governanceItemsAreManageable: boolean;
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceMappedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    controlIdentifier?: string;
    openExternalUrl: (url: string) => void;
}

interface GovernanceLibraryTableState {
    currentSort: string;
    currentSortDirection: SortDirection;
}

const GovernanceLibraryTable = (props: GovernanceLibraryTableProps): JSX.Element => {
    const [governanceLibraryTableState, setGovernanceLibraryTableState] = useState<GovernanceLibraryTableState>({ currentSort: GovernanceSortFilterOptions.TITLE, currentSortDirection: SortDirection.ASC });

    const sortGovernance = (): GovernanceVersion[] => {
        let sortResult = 0;

        return props.governanceItems.sort((governanceItemA, governanceItemB) => {
            switch (governanceLibraryTableState.currentSort) {
                case GovernanceSortFilterOptions.EFFECTIVE_DATE:
                case GovernanceSortFilterOptions.TITLE:
                    sortResult = (governanceItemA[governanceLibraryTableState.currentSort] as string).localeCompare(governanceItemB[governanceLibraryTableState.currentSort] as string);
                    break;
                case GovernanceSortFilterOptions.OWNER:
                    sortResult = compareUsersBySubjectForSorting(governanceItemA.owner_user_id, governanceItemB.owner_user_id, props.users);
                    break;
                case GovernanceSortFilterOptions.CONTROLS_MAPPED:
                    sortResult = undefinedComparator(governanceItemA.associated_controls, governanceItemB.associated_controls, (associatedControlsA, associatedControlsB) => {
                        if (associatedControlsA.length > associatedControlsB.length) {
                            return 1;
                        }
                        return -1;
                    });
                    break;
                default:
                    sortResult = 0;
                    break;
            }

            return governanceLibraryTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setGovernanceLibraryTableState({ currentSort: newSort as GovernanceSortFilterOptions, currentSortDirection: newSortDirection });
    };

    const tableRow = (governanceItem: GovernanceVersion): JSX.Element => {
        const governanceLibraryTableRowProps: GovernanceLibraryTableRowProps = {
            documentApi: props.documentApi,
            governanceApi: props.governanceApi,
            governanceItem: governanceItem,
            governanceItemIsManageable: props.governanceItemsAreManageable,
            users: props.users,
            displayGovernanceTextModal: props.displayGovernanceTextModal,
            displayGovernanceMappedControlsModal: props.displayGovernanceMappedControlsModal,
            controlIdentifier: props.controlIdentifier,
            openExternalUrl: props.openExternalUrl,
        };
        return <GovernanceLibraryTableRow key={governanceItem.id} {...governanceLibraryTableRowProps} />;
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: [
            { dataKey: GovernanceSortFilterOptions.TITLE, label: 'TITLE' },
            { dataKey: GovernanceSortFilterOptions.OWNER, label: 'OWNER' },
            { dataKey: GovernanceSortFilterOptions.CONTROLS_MAPPED, label: 'CONTROLS' },
            { dataKey: GovernanceSortFilterOptions.EFFECTIVE_DATE, label: 'EFFECTIVE DATE' },
        ],
        applySorting: setCurrentSort,
        currentSort: governanceLibraryTableState.currentSort,
        currentSortDirection: governanceLibraryTableState.currentSortDirection,
        tableIncludesOverflowMenu: true,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortGovernance().map(tableRow)}</TableBody>
        </Table>
    );
};

export default GovernanceLibraryTable;
