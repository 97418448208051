import { Component } from 'react';

import FormFieldMultiOptionSelect from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

export interface ServiceMultiOptionFilterProps {
    filterOptions: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];
    vendorFilter: (selectedFilterOptions: Filter | Filter[]) => void;
}

export default class ServiceMultiOptionFilter extends Component<ServiceMultiOptionFilterProps> {
    handleSelectChange = (value: GroupOptionType[]): void => {
        const riskRatingFilterValues: (string | number)[] = [];
        const residualRiskFilterValues: (string | number)[] = [];
        const vendorFilterValues: (string | number)[] = [];
        const assessmentDueDateFilterValues: (string | number)[] = [];

        value.forEach((filter) => {
            if (filter.groupId === ServiceListingSortFilterOptions.VENDOR_ID) {
                vendorFilterValues.push(filter.value);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.RISKRATING) {
                riskRatingFilterValues.push(filter.value);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.RESIDUAL_RISK) {
                //Trim residual risk prefix. See below.
                const trimmed = parseFloat((filter.value as string).split('-')[0]);
                residualRiskFilterValues.push(trimmed);
            }
            if (filter.groupId === ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE) {
                assessmentDueDateFilterValues.push(filter.value);
            }
        });

        const bothFilters: Filter[] = [
            {
                key: ServiceListingSortFilterOptions.RISKRATING,
                value: riskRatingFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.RESIDUAL_RISK,
                value: residualRiskFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.VENDOR_ID,
                value: vendorFilterValues,
            },
            {
                key: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
                value: assessmentDueDateFilterValues,
            },
        ];
        this.props.vendorFilter(bothFilters);
    };

    render(): JSX.Element {
        // Since residual risk and inherent risk have the same value, a prefix is added to the residual risk values
        const filterOptions = this.props.filterOptions.map((groupOption) => {
            if (groupOption.label === 'Service Residual Risk') {
                groupOption.options.map((option) => {
                    option.value = `${option.value}-residual`;
                    return option;
                });
            }
            return groupOption;
        });
        return <FormFieldMultiOptionSelect options={filterOptions} defaultSelectedOptions={this.props.defaultSelectedOptions} formFieldLabel="Filter by Service Attributes" handleChange={this.handleSelectChange} accessibilityLabel="Service Filter" />;
    }
}
