import { Component } from 'react';

import styles from './PageContent.module.css';

export interface PageContentProps {
    children?: React.ReactNode;
    removePadding?: boolean;
}

export default class PageContent extends Component<PageContentProps> {
    render(): JSX.Element {
        return <div className={`${styles.pageContent} ${this.props.removePadding === true && styles.removePadding}`}>{this.props.children}</div>;
    }
}
