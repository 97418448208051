import { useState } from 'react';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldTextArea from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { SaveControlGroupRequest } from 'Models/OperationalControls';

import styles from './SaveControlGroup.module.css';

export type CreateControlGroupStatus = 'idle' | 'saving' | 'saved' | 'error';

interface SaveControlGroupFormFieldsValues {
    customControlGroupName?: string;
    customControlGroupDescription?: string;
}

export const useSaveControlGroup = (controlFramework: string, controlsApi: ControlsApi, defaultSaveControlGroupFormFieldsValues: SaveControlGroupFormFieldsValues, existingControlGroupId?: string) => {
    const [saveControlGroupStatus, setSaveControlGroupStatus] = useState<CreateControlGroupStatus>('idle');
    const [error, setError] = useState<Error>();
    const [saveControlGroupFormFieldsValues, setSaveControlGroupFormFieldsValues] = useState<SaveControlGroupFormFieldsValues>({ ...defaultSaveControlGroupFormFieldsValues });

    const saveControlGroup = async () => {
        setSaveControlGroupStatus('saving');
        try {
            if (saveControlGroupFormFieldsValues.customControlGroupName && saveControlGroupFormFieldsValues.customControlGroupDescription) {
                const saveControlGroup: SaveControlGroupRequest = {
                    control_group_name: saveControlGroupFormFieldsValues.customControlGroupName,
                    control_group_description: saveControlGroupFormFieldsValues.customControlGroupDescription,
                };
                await controlsApi.saveCustomControlGroup(controlFramework, saveControlGroup, existingControlGroupId);
                setSaveControlGroupStatus('saved');
            } else {
                setSaveControlGroupStatus('error');
                setError(new Error('Error: Not all required fields have values.'));
            }
        } catch (err) {
            handleRequestError(err);
        }
    };

    const handleRequestError = (error: Error) => {
        setSaveControlGroupStatus('error');
        setError(error);
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setSaveControlGroupFormFieldsValues({ ...saveControlGroupFormFieldsValues, [event.currentTarget.name]: event.currentTarget.value });
    };

    return {
        saveControlGroupStatus: saveControlGroupStatus,
        saveControlGroupFormFieldsValues: saveControlGroupFormFieldsValues,
        error: error,
        saveControlGroup: saveControlGroup,
        handleChange: handleChange,
    };
};

export interface SaveControlGroupFromFieldsProps {
    saveControlGroupFormFieldsValues: SaveControlGroupFormFieldsValues;
    handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const SaveControlGroupFormFields = ({ disabled = false, ...props }: SaveControlGroupFromFieldsProps) => {
    return (
        <>
            <div className={styles.formFieldContainer}>
                <FormFieldText disabled={disabled} value={props.saveControlGroupFormFieldsValues.customControlGroupName} formFieldId="customControlGroupName" formFieldLabel="Name" handleChange={props.handleChange} tooltip="The name used to identify this control group." required />
            </div>
            <div className={styles.formFieldContainer}>
                <FormFieldTextArea disabled={disabled} value={props.saveControlGroupFormFieldsValues.customControlGroupDescription} formFieldId="customControlGroupDescription" formFieldLabel="Description" handleChange={props.handleChange} tooltip="A description of what is assessed by the controls in this control group." required />
            </div>
        </>
    );
};
