import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { RiskRating, Service, VendorResponseWithServices } from 'Models/TPRM';

import DeleteTPRMServiceModal, { DeleteTPRMServiceModalProps } from './DeleteTPRMServiceModal/DeleteTPRMServiceModal';
import styles from './ManageTPRMServices.module.css';
import SaveTPRMVendorServiceModal, { SaveTPRMVendorServiceModalProps } from './SaveTPRMVendorServiceModal/SaveTPRMVendorServiceModal';
import ServiceListing, { ServiceDisplayData, ServiceListingProps } from './ServiceListing/ServiceListing';
import ServiceAssessmentDueDateModal, { ServiceAssessmentDueDateModalProps } from '../ServiceAssessmentDueDateModal/ServiceAssessmentDueDateModal';

export interface ManageTPRMServicesProps {
    tprmApi: TPRMApi;
}

export enum Modals {
    DeleteTPRMServiceModal,
    SaveTPRMVendorServiceModal,
    ServiceAssessmentDueDateModal,
    None,
}

export interface ManageTPRMServicesRouteState {
    createServiceForVendor?: VendorResponseWithServices;
}

const ManageTPRMServices = (props: ManageTPRMServicesProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultVendorByWhichToFilter, setDefaultVendorByWhichToFilter] = useState<VendorResponseWithServices>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [selectedService, setSelectedService] = useState<Service>();
    const [selectedVendor, setSelectedVendor] = useState<VendorResponseWithServices>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>(false);
    const { users } = useUsers();
    const [vendors, setVendors] = useState<VendorResponseWithServices[]>();

    const getVendors = useCallback(async (): Promise<void> => {
        try {
            const vendorResponse = await props.tprmApi.getVendors();
            const vendors = vendorResponse.data;
            setVendors(vendors);

            const searchParams = new URLSearchParams(location.search);
            const vendorId = searchParams.get('vendorId');
            const defaultVendorByWhichToFilter = vendorId ? vendors.find((vendor) => vendor.id === vendorId) : undefined;
            setDefaultVendorByWhichToFilter(defaultVendorByWhichToFilter);
        } catch (error) {
            handleRequestError(error);
        }
    }, [location.search, props.tprmApi]);

    useEffect(() => {
        getVendors();

        if (location.state && (location.state as ManageTPRMServicesRouteState).createServiceForVendor) {
            // Prevent showing the modal every time the page is reloaded.
            navigate(location.pathname, { state: {}, replace: true });

            setDisplayedModal(Modals.SaveTPRMVendorServiceModal);
            setSelectedVendor((location.state as ManageTPRMServicesRouteState).createServiceForVendor);
            setTprmAccessDenied(false);
        }
    }, [getVendors, location.pathname, location.state, navigate]);

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modals.None);
        setSelectedService(undefined);
        setSelectedVendor(undefined);
    };

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const displayModalWithService = (modal: Modals, selectedService: ServiceDisplayData): void => {
        if (vendors) {
            const service = vendors.find((v) => v.id === selectedService.vendorId)?.services.find((s) => s.id === selectedService.serviceID);
            setSelectedService(service);
            setDisplayedModal(modal);
        }
    };

    const getDeleteTPRMServiceModalProps = (): DeleteTPRMServiceModalProps => {
        const deleteTPRMServiceModalProps: DeleteTPRMServiceModalProps = {
            hideModal: hideModal,
            serviceDeleted: () => getVendors(),
            tprmApi: props.tprmApi,
            service: selectedService!,
        };

        return deleteTPRMServiceModalProps;
    };

    const getSetTPRMServiceDueDateModalProps = (): ServiceAssessmentDueDateModalProps => {
        return {
            hideModal: hideModal,
            serviceAssessmentDueDateSet: () => getVendors(),
            tprmApi: props.tprmApi,
            service: selectedService!,
        };
    };

    const createServiceListing = (vendors: VendorResponseWithServices[]): ServiceDisplayData[] => {
        return vendors.flatMap((vendor) => {
            return vendor.services.map((service) => {
                return {
                    vendorName: vendor.name,
                    vendorId: vendor.id,
                    serviceName: service.name,
                    serviceID: service.id,
                    riskRating: service.inherent_risk_score,
                    vendorManagerId: service.vendor_service_manager_user_id,
                    dateCreated: service.created_time,
                    commonAssessmentChildren: service.common_assessment_children,
                    residualRisk: service.assessment_residual_risk_score ? service.assessment_residual_risk_score : RiskRating.INACTIVE,
                    assessmentDueDate: service.assessment_due_date,
                };
            });
        });
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (vendors && users) {
        const saveTPRMVendorServiceModalProps: SaveTPRMVendorServiceModalProps = {
            defaultVendor: selectedVendor,
            hideModal: hideModal,
            tprmApi: props.tprmApi,
            users: users,
            vendors: vendors,
            vendorService: selectedService,
            vendorServiceSaved: () => getVendors(),
        };
        const serviceListingProps: ServiceListingProps = {
            vendors: vendors,
            services: createServiceListing(vendors),
            users: users,
            defaultVendorByWhichToFilter: defaultVendorByWhichToFilter,
            selectedModifyService: (service: ServiceDisplayData) => displayModalWithService(Modals.SaveTPRMVendorServiceModal, service),
            selectedDeleteService: (service: ServiceDisplayData) => displayModalWithService(Modals.DeleteTPRMServiceModal, service),
            selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => displayModalWithService(Modals.ServiceAssessmentDueDateModal, service),
        };

        return (
            <>
                {displayedModal === Modals.DeleteTPRMServiceModal && <DeleteTPRMServiceModal {...getDeleteTPRMServiceModalProps()} />}
                {displayedModal === Modals.SaveTPRMVendorServiceModal && <SaveTPRMVendorServiceModal {...saveTPRMVendorServiceModalProps} />}
                {displayedModal === Modals.ServiceAssessmentDueDateModal && <ServiceAssessmentDueDateModal {...getSetTPRMServiceDueDateModalProps()} />}

                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Manage Vendor Services
                            </Text>
                            {vendors.length > 0 && (
                                <Button variant="primary" onClick={() => displayModal(Modals.SaveTPRMVendorServiceModal)} fontAwesomeImage={faPlus}>
                                    CREATE NEW SERVICE
                                </Button>
                            )}
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <ServiceListing {...serviceListingProps} />
                    </PageContent>
                </PageBackground>
            </>
        );
    }

    return <Placeholder />;
};

export default ManageTPRMServices;
