import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { FOLDERS, SERVICES, TPRM, VENDORS, VENDOR_ID } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { VendorResponseWithServices } from 'Models/TPRM';

import DeleteTPRMVendorModal, { DeleteTPRMVendorModalProps } from './DeleteTPRMVendorModal/DeleteTPRMVendorModal';
import styles from './ManageTPRMVendors.module.css';
import SaveTPRMVendorModal, { SaveTPRMVendorModalProps } from './SaveTPRMVendorModal/SaveTPRMVendorModal';
import VendorListing, { VendorListingProps } from './VendorListing/VendorListing';
import { ManageTPRMServicesRouteState } from '../ManageTPRMServices/ManageTPRMServices';

export interface ManageTPRMVendorsProps {
    tprmApi: TPRMApi;
}

export enum Modal {
    DeleteTPRMVendorModal,
    SaveTPRMVendorModal,
    None,
}

const ManageTPRMVendors = (props: ManageTPRMVendorsProps) => {
    const navigate = useNavigate();
    const { users } = useUsers();
    const [vendors, setVendors] = useState<VendorResponseWithServices[]>();
    const [displayedModal, setDisplayedModal] = useState<Modal>();
    const [selectedVendor, setSelectedVendor] = useState<VendorResponseWithServices>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();

    const getVendors = useCallback(async (): Promise<void> => {
        try {
            const vendorResponse = await props.tprmApi.getVendors();
            setVendors(vendorResponse.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.tprmApi]);

    useEffect(() => {
        getVendors();
    }, [getVendors]);

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const displayModal = (modal: Modal): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modal.None);
        setSelectedVendor(undefined);
    };

    const selectedModifyVendor = (vendorId: string): void => {
        if (vendors) {
            const vendor = vendors.find((v) => v.id === vendorId);
            setDisplayedModal(Modal.SaveTPRMVendorModal);
            setSelectedVendor(vendor);
        }
    };

    const selectedDeleteVendor = (vendorId: string): void => {
        if (vendors) {
            const vendor = vendors.find((v) => v.id === vendorId);
            setDisplayedModal(Modal.DeleteTPRMVendorModal);
            setSelectedVendor(vendor);
        }
    };

    const getDeleteTPRMVendorModalProps = (): DeleteTPRMVendorModalProps => {
        const deleteTPRMVendorModalProps: DeleteTPRMVendorModalProps = {
            hideModal: hideModal,
            vendorDeleted: () => getVendors(),
            tprmApi: props.tprmApi,
            vendor: selectedVendor!,
        };

        return deleteTPRMVendorModalProps;
    };

    const createServiceForVendor = (vendor: VendorResponseWithServices): void => {
        const state: ManageTPRMServicesRouteState = { createServiceForVendor: vendor };
        navigate(`/${TPRM}/${SERVICES}`, { state: state });
    };

    const viewAllServicesForVendor = (vendor: VendorResponseWithServices): void => {
        navigate(`/${TPRM}/${SERVICES}?${VENDOR_ID}=${vendor.id}`);
    };

    const viewFolders = (vendorId: string): void => {
        navigate(`/${TPRM}/${VENDORS}/${vendorId}/${FOLDERS}`);
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (vendors && users) {
        const vendorListingProps: VendorListingProps = {
            users: users,
            vendors: vendors,
            selectedModifyVendor: selectedModifyVendor,
            selectedDeleteVendor: selectedDeleteVendor,
            selectedCreateServiceForVendor: createServiceForVendor,
            selectedViewAllServicesForVendor: viewAllServicesForVendor,
            selectedViewFolders: viewFolders,
        };

        const saveTPRMVendorModalProps: SaveTPRMVendorModalProps = {
            hideModal: hideModal,
            tprmApi: props.tprmApi,
            vendor: selectedVendor,
            vendorSaved: () => getVendors(),
        };

        return (
            <>
                {displayedModal === Modal.SaveTPRMVendorModal && <SaveTPRMVendorModal {...saveTPRMVendorModalProps} />}
                {displayedModal === Modal.DeleteTPRMVendorModal && <DeleteTPRMVendorModal {...getDeleteTPRMVendorModalProps()} />}

                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Manage Vendors
                            </Text>
                            <Button variant="primary" onClick={() => displayModal(Modal.SaveTPRMVendorModal)} fontAwesomeImage={faPlus}>
                                CREATE NEW VENDOR
                            </Button>
                        </div>
                    </PageContent>
                </PageBackground>

                <PageBackground color="white">
                    <PageContent>
                        <VendorListing {...vendorListingProps} />
                    </PageContent>
                </PageBackground>
            </>
        );
    }

    return <Placeholder />;
};

export default ManageTPRMVendors;
