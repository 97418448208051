import { useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { DropdownItemClickType, PrimaryAndSecondaryDropdownButtonProps, PrimaryDropdownButton } from 'Components/Buttons/DropdownButton';
import DeleteLimitModal, { DeleteLimitModalProps } from 'Components/Limits/DeleteLimitModal/DeleteLimitModal';
import CreateEffectivenessLimitModal, { CreateEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/CreateEffectivenessLimitModal/CreateEffectivenessLimitModal';
import ModifyEffectivenessLimitModal, { ModifyEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/ModifyEffectivenessLimitModal/ModifyEffectivenessLimitModal';
import { LimitsTables, LimitsTablesProps } from 'Components/Limits/LimitsTables/LimitsTables';
import CreateMetricsLimitModal, { CreateMetricsLimitModalProps, MetricOptions } from 'Components/Limits/Metric/CreateMetricLimitsModal/CreateMetricsLimitModal';
import ModifyMetricLimitModal, { ModifyMetricLimitModalProps } from 'Components/Limits/Metric/ModifyMetricLimitModal/ModifyMetricLimitModal';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { IntegrationResponse, MetricName } from 'Models/ExternalIntegrations';
import { EffectivenessLimit, Limit, LimitResponse, LimitType, MetricLimit } from 'Models/Limits';
import { OperationalControl } from 'Models/OperationalControls';

import { ControlDeleted } from '../ControlSettings';

export interface ControlLimitsTabProps {
    limitResponseList: LimitResponse[];
    alertsLimitsApi: AlertsLimitsApi;
    getControlLimits: () => void;
    detailedControlResponse: OperationalControl;
    controlMetricMapping: IntegrationResponse[];
}

export enum Modals {
    CreateEffectivenessLimitModal,
    CreateMetricLimitModal,
    DeleteLimitModal,
    ModifyEffectivenessLimitModal,
    ModifyMetricLimitModal,
    None,
}

export const ControlLimitsTab = ({ controlIsDeleted = false, ...props }: ControlLimitsTabProps & ControlDeleted) => {
    const [displayedModal, setDisplayedModal] = useState(Modals.None);
    const [deleteLimitId, setDeleteLimitId] = useState<string>();
    const [deleteLimitTitle, setDeleteLimitTitle] = useState<string>();
    const [modifyLimit, setModifyLimit] = useState<Limit>();

    const createMetricIntegrationOptions = (): MetricOptions[] => {
        const createMetricIntegrationOptions: MetricOptions[] = [];
        props.controlMetricMapping.forEach((integration) => {
            const metric_map: MetricName[] = integration.metrics.map((metric) => {
                return metric.metric_name;
            });
            const integrationType: MetricOptions = {
                integrationName: integration.integration_name,
                metricNames: metric_map,
            };
            createMetricIntegrationOptions.push(integrationType);
        });
        props.limitResponseList.forEach((limit) => {
            if (limit._type === LimitType.METRIC) {
                createMetricIntegrationOptions.forEach((integration) => {
                    if ((limit as MetricLimit).integration_name === integration.integrationName) {
                        if (integration.metricNames.includes((limit as MetricLimit).metric_name)) {
                            const index = integration.metricNames.indexOf((limit as MetricLimit).metric_name);
                            integration.metricNames.splice(index, 1);
                        }
                    }
                });
            }
        });
        const metricFilterOptions = createMetricIntegrationOptions.filter((integration) => integration.metricNames.length > 0);

        return metricFilterOptions;
    };

    const getControlLimitsDropdownButton = () => {
        const dropdownButtonProps: PrimaryAndSecondaryDropdownButtonProps = {
            dropdownItems: [],
        };

        const isEffectivenessLimit = (limitResponse: LimitResponse) => limitResponse._type === LimitType.EFFECTIVENESS;

        if (!props.limitResponseList.some(isEffectivenessLimit)) {
            dropdownButtonProps.dropdownItems.push({
                _type: DropdownItemClickType.ONCLICK,
                text: 'Create Effectiveness Limit',
                onClick: () => setDisplayedModal(Modals.CreateEffectivenessLimitModal),
            });
        }

        const metricIntegrationOptions = createMetricIntegrationOptions();

        if (metricIntegrationOptions.length > 0) {
            dropdownButtonProps.dropdownItems.push({
                _type: DropdownItemClickType.ONCLICK,
                text: 'Create Metric Limit',
                onClick: () => setDisplayedModal(Modals.CreateMetricLimitModal),
            });
        }

        if (dropdownButtonProps.dropdownItems.length > 0) {
            return <PrimaryDropdownButton {...dropdownButtonProps}>ADD LIMITS</PrimaryDropdownButton>;
        }
    };

    const displayDeleteLimitModal = (deleteLimitId: string, deleteLimitTitle: string): void => {
        setDeleteLimitId(deleteLimitId);
        setDeleteLimitTitle(deleteLimitTitle);
        setDisplayedModal(Modals.DeleteLimitModal);
    };

    const displayModifyEffectivenessLimitModal = (modifyLimit: Limit): void => {
        setModifyLimit(modifyLimit);
        if (modifyLimit._type === LimitType.EFFECTIVENESS) {
            setDisplayedModal(Modals.ModifyEffectivenessLimitModal);
        } else {
            setDisplayedModal(Modals.ModifyMetricLimitModal);
        }
    };

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const metricFilterOptions = createMetricIntegrationOptions();
    if (metricFilterOptions) {
        const createEffectivenessLimitModalProps: CreateEffectivenessLimitModalProps = {
            hideModal: () => displayModal(Modals.None),
            alertsLimitsApi: props.alertsLimitsApi,
            onLimitCreated: props.getControlLimits,
            controlIdentifier: props.detailedControlResponse.identifier,
        };
        const createMetricsLimitModalProps: CreateMetricsLimitModalProps = {
            hideModal: () => displayModal(Modals.None),
            alertsLimitsApi: props.alertsLimitsApi,
            onLimitCreated: props.getControlLimits,
            controlIdentifier: props.detailedControlResponse.identifier,
            createMetricIntegrationOptions: metricFilterOptions,
        };
        const limitsTableProps: LimitsTablesProps = {
            limitResponseList: props.limitResponseList,
            displayDeleteLimitModal: displayDeleteLimitModal,
            displayModifyLimitModal: displayModifyEffectivenessLimitModal,
            alertsLimitsApi: props.alertsLimitsApi,
            addLimitsButton: getControlLimitsDropdownButton(),
        };
        const deleteLimitModalProps: DeleteLimitModalProps = {
            alertsLimitsApi: props.alertsLimitsApi,
            deleteLimitId: deleteLimitId!,
            deleteLimitTitle: deleteLimitTitle!,
            hideModal: () => displayModal(Modals.None),
            updateLimitListing: props.getControlLimits,
        };
        const modifyEffectivenessLimitModalProps: ModifyEffectivenessLimitModalProps = {
            alertsLimitsApi: props.alertsLimitsApi,
            hideModal: () => displayModal(Modals.None),
            limit: modifyLimit! as EffectivenessLimit,
            onLimitModified: props.getControlLimits,
        };
        const modifyMetricLimitModalProps: ModifyMetricLimitModalProps = {
            alertsLimitsApi: props.alertsLimitsApi,
            hideModal: () => displayModal(Modals.None),
            metricLimit: modifyLimit! as MetricLimit,
            onLimitModified: props.getControlLimits,
        };

        if (controlIsDeleted) {
            return <Text>This control and all associated limits have been deleted.</Text>;
        }
        return (
            <>
                {displayedModal === Modals.CreateEffectivenessLimitModal && <CreateEffectivenessLimitModal {...createEffectivenessLimitModalProps} />}
                {displayedModal === Modals.DeleteLimitModal && <DeleteLimitModal {...deleteLimitModalProps} />}
                {displayedModal === Modals.ModifyEffectivenessLimitModal && <ModifyEffectivenessLimitModal {...modifyEffectivenessLimitModalProps} />}
                {displayedModal === Modals.CreateMetricLimitModal && <CreateMetricsLimitModal {...createMetricsLimitModalProps} />}
                {displayedModal === Modals.ModifyMetricLimitModal && <ModifyMetricLimitModal {...modifyMetricLimitModalProps} />}
                <LimitsTables {...limitsTableProps} />
            </>
        );
    }
    return <Placeholder />;
};
