import { faBan, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { UserNameFormat, getUserNameFromUser } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

import styles from './DeactivateUserModal.module.css';

export interface DeactivateUserModalProps {
    hideModal: () => void;
    userSelectedForDeactivation: UserResponse;
    usersApi: UsersApi;
    userDeactivated: () => void;
}

export interface DeactivateUserModalState {
    successMessage?: string;
    failureMessage?: string;
    isDeactivating: boolean;
}

export default class DeactivateUserModal extends Component<DeactivateUserModalProps, DeactivateUserModalState> {
    constructor(props: DeactivateUserModalProps) {
        super(props);

        this.state = {
            isDeactivating: false,
        };
    }

    deactivateUser = async (): Promise<void> => {
        this.setState({ isDeactivating: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.usersApi.deactivateUser(this.props.userSelectedForDeactivation.cognito_subject);
            this.setState({ successMessage: 'User deactivated.' });
            this.props.userDeactivated();
        } catch (error) {
            this.handleRequestError(error);
        } finally {
            this.setState({ isDeactivating: false });
        }
    };

    handleRequestError = (error: Error): void => {
        this.setState({ failureMessage: error.message, successMessage: undefined });
    };

    hideModal = (): void => {
        if (!this.state.isDeactivating) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <ModalHeader text="Deactivate" />
                    <div className={styles.deactivateText}>Deactivating a user will prevent the user from logging in or receiving notifications.</div>
                    <div className={styles.deactivateText}>Are you sure you want to deactivate: {getUserNameFromUser(this.props.userSelectedForDeactivation, UserNameFormat.FIRST_SPACE_LAST)}?</div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={this.hideModal} disabled={this.state.isDeactivating} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="danger" onClick={() => this.deactivateUser()} loadingText={'Deactivating...'} isLoading={this.state.isDeactivating} disabled={this.state.successMessage ? true : false} fontAwesomeImage={faBan}>
                            DEACTIVATE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
