import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import GovernanceLibraryTable, { GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

export interface RetiredGovernanceTabProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItems: GovernanceVersion[];
    users: UserResponse[];
    openExternalUrl: (url: string) => void;
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
}

const RetiredGovernanceTab = (props: RetiredGovernanceTabProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        users: props.users,
        governanceItems: props.governanceItems,
        governanceItemsAreManageable: false,
        openExternalUrl: props.openExternalUrl,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
    };

    return <GovernanceLibraryTable {...governanceListingTableProps} />;
};

export default RetiredGovernanceTab;
