import { faArrowUpRightFromSquare, faComment, faDownload, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import ViewGovernanceLibraryDefinitionModal, { ViewGovernanceLibraryDefinitionModalProps } from 'Components/Governance/ViewGovernanceLibraryDefinitionModal/ViewGovernanceLibraryDefinitionModal';
import AssociatedControlsModal, { AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import FutureGovernanceVersionTooltip from 'Components/Tooltips/FutureGovernanceVersionTooltip';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { CREATE, GOVERNANCE } from 'Config/Paths';
import { getFrameworkGroupControlParts, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument } from 'Helpers/FileUtils';
import { Navigator } from 'Helpers/Navigator';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { UploadedFile } from 'Models/Files';
import { GovernanceContentType, GovernanceStatus, GovernanceVersion, TextBasedGovernanceVersion, getHumanReadableGovernanceType } from 'Models/Governance';
import { OperationalControl } from 'Models/OperationalControls';
import { RETIRED_TAB_KEY } from 'Pages/Governance/GovernanceLibrary/GovernanceLibrary';

import styles from './ManageGovernance.module.css';

export interface ManageGovernanceProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    controlsApi: ControlsApi;
    navigator: Navigator;
}

interface PathParams {
    governanceId: string;
}

enum Modal {
    RetireGovernance,
    None,
}

/**
 * Displays a list of all versions for a "governance"--a policy, standard, or procedure.
 * Each version can be viewed from here. Depending on the "content type" of the governance, that means opening an external URL, downloading a document, or viewing text.
 * The user can also choose to create a new version of the governance or retire the governance from this page.
 *
 * Although "ManageGovernance" is in line with the wording we use in the UI, it's a little misleading in code: this is what we normally call a "Details" page.
 */
const ManageGovernance = (props: ManageGovernanceProps) => {
    const { governanceId } = useParams<keyof PathParams>() as PathParams;

    const [versions, setVersions] = useState<GovernanceVersion[]>();
    const [currentVersion, setCurrentVersion] = useState<GovernanceVersion>();
    const [displayedTextBasedGovernance, setDisplayedTextBasedGovernance] = useState<TextBasedGovernanceVersion>();
    const [displayedVersionAssociatedControls, setDisplayedVersionAssociatedControls] = useState<GovernanceVersion>();
    const { users } = useUsers();
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState<boolean>(false);
    const [controlDetails, setControlDetails] = useState<OperationalControl>();
    const [displayedModal, setDisplayedModal] = useState<Modal>(Modal.None);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const controlIdQueryParam = query.get('controlId');

    useEffect(() => {
        if (!controlIdQueryParam) {
            return;
        }

        const getControlDetails = async () => {
            try {
                const controlParts = controlIdQueryParam.split('#');
                const response = await props.controlsApi.getControlDetails(controlParts[0], controlParts[1], controlParts[2]);
                setControlDetails(response.data);
            } catch (error) {
                setLoadingErrorOccurred(true);
            }
        };
        getControlDetails();
    }, [controlIdQueryParam, props.controlsApi]);

    const getAllVersions = useCallback(async () => {
        try {
            const response = await props.governanceApi.getAllVersionsForGovernance(governanceId);
            setVersions(response.data);
        } catch (error) {
            setLoadingErrorOccurred(true);
        }
    }, [props.governanceApi, governanceId]);

    useEffect(() => {
        getAllVersions();
    }, [getAllVersions]);

    useEffect(() => {
        if (!versions) {
            return;
        }

        const effectiveVersion = versions.find((version) => version.is_effective);
        setCurrentVersion(effectiveVersion ?? versions[0]);
    }, [versions]);

    const downloadGovernanceFile = async (file: UploadedFile): Promise<void> => {
        try {
            downloadDocument(props.documentApi, file);
        } catch (error) {
            console.log(error);
        }
    };

    const retireGovernance = async (): Promise<string> => {
        await props.governanceApi.retireGovernance(governanceId);
        await getAllVersions();
        return `${getHumanReadableGovernanceType(currentVersion!.type)} retired.`;
    };

    const actionButton = (version: GovernanceVersion): JSX.Element => {
        switch (version.content_type) {
            case GovernanceContentType.DOCUMENT:
                return (
                    <Button variant="secondary" fontAwesomeImage={faDownload} onClick={() => downloadGovernanceFile(version.file)}>
                        Download
                    </Button>
                );
            case GovernanceContentType.TEXT:
                return (
                    <Button variant="secondary" fontAwesomeImage={faComment} onClick={() => setDisplayedTextBasedGovernance(version)}>
                        View
                    </Button>
                );
            case GovernanceContentType.EXTERNAL_URL:
                return (
                    <Button
                        variant="secondary"
                        fontAwesomeImage={faArrowUpRightFromSquare}
                        onClick={() => {
                            if (version.external_url) {
                                props.navigator.openExternalUrl(version.external_url, true);
                            }
                        }}
                    >
                        Open Link
                    </Button>
                );
        }
    };

    const accordionForVersion = (version: GovernanceVersion): JSX.Element => {
        const key = `${version.effective_date}${version.last_updated}`;
        const formattedEffectiveDate = iso8601ToUsDateLong(version.effective_date);

        return (
            <Accordion key={key}>
                <div className={styles.accordionContainer}>
                    <div className={styles.accordionColumnTitle}>
                        <AccordionToggle eventKey={key} ariaLabelSuffix={`${version.title} (effective ${formattedEffectiveDate})`} />
                        <div className={styles.accordionColumnTextContainer}>
                            <div className={styles.titleAndTooltipContainer}>
                                <Text variant="Text3" noStyles>
                                    <b>{version.title}</b>
                                </Text>
                                <FutureGovernanceVersionTooltip version={version} />
                            </div>
                            <Text variant="Text3" noStyles color="darkGray">
                                {getHumanReadableGovernanceType(version.type)}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.accordionColumnOwner}>
                        <Text variant="Text3" noStyles>
                            {getUserNameFromSubject(version.owner_user_id, users)}
                        </Text>
                    </div>
                    <div className={styles.accordionColumnControls}>
                        <Button variant="linkText" size="sm" onClick={() => setDisplayedVersionAssociatedControls(version)}>{`${version.associated_controls.length || '0'} ${version.associated_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
                    </div>
                    <div className={styles.accordionColumnEffectiveDate}>
                        <Text variant="Text3" noStyles>
                            {formattedEffectiveDate}
                        </Text>
                    </div>
                    <div className={styles.accordionColumnAction}>
                        <Text variant="Text3" noStyles>
                            {actionButton(version)}
                        </Text>
                    </div>
                </div>
                <AccordionCollapse eventKey={key}>
                    <div className={styles.additionalInfo}>
                        <div className={styles.lastUpdatedInfoContainer}>
                            <div className={styles.lastUpdatedInfo}>
                                <Text noStyles>
                                    <b>Last Updated By:</b>
                                </Text>
                                <Text noStyles>{getUserNameFromSubject(version.last_updated_by, users)}</Text>
                            </div>
                            <div className={styles.lastUpdatedInfo}>
                                <Text noStyles>
                                    <b>Last Updated:</b>
                                </Text>
                                <Text noStyles>{iso8601ToUsDateLong(version.last_updated)}</Text>
                            </div>
                        </div>
                        <Text noStyles>
                            <b>Changelog:</b>
                        </Text>
                        <Text noStyles>{version.changelog}</Text>
                    </div>
                </AccordionCollapse>
                <hr className={styles.hrModifier} />
            </Accordion>
        );
    };

    const viewGovernanceLibraryDefinitionModalProps = (governance: TextBasedGovernanceVersion): ViewGovernanceLibraryDefinitionModalProps => {
        return {
            hideModal: () => setDisplayedTextBasedGovernance(undefined),
            governance: governance,
        };
    };

    const associatedControlModalProps = (governanceWithAssociatedControls: GovernanceVersion): AssociatedControlsModalProps => {
        return {
            hideModal: () => setDisplayedVersionAssociatedControls(undefined),
            associatedControls: governanceWithAssociatedControls.associated_controls,
        };
    };

    const getBreadcrumb = (): JSX.Element => {
        if (controlDetails) {
            const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(controlDetails);
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                    <BreadcrumbLink link={getFrameworkGroupControlURL(getOperationalControlIdentifierString(controlFramework, controlGroupId))}>{controlDetails.metadata.control_group_name}</BreadcrumbLink>
                    <BreadcrumbLink link={`${getFrameworkGroupControlURL(getOperationalControlIdentifierString(controlFramework, controlGroupId, controlId))}#governance`}>{controlDetails.metadata.is_custom ? controlDetails.metadata.control_name : controlId}</BreadcrumbLink>
                    <BreadcrumbText>{currentVersion?.title}</BreadcrumbText>
                </Breadcrumb>
            );
        } else {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${GOVERNANCE}${currentVersion?.status === GovernanceStatus.RETIRED ? `#${RETIRED_TAB_KEY}` : ''}`}>Governance Integration</BreadcrumbLink>
                    <BreadcrumbText>{currentVersion?.title}</BreadcrumbText>
                </Breadcrumb>
            );
        }
    };

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (!versions || !currentVersion || !users || (controlIdQueryParam && !controlDetails)) {
        return <Placeholder />;
    }

    return (
        <>
            {displayedModal === Modal.RetireGovernance && (
                <ConfirmationModal operationType="destructive" headerText={`Retire ${getHumanReadableGovernanceType(currentVersion.type)}`} areYouSureText={`Are you sure you want to permanently retire "${currentVersion.title}"?`} buttonText="RETIRE" buttonLoadingText="Retiring..." buttonIcon={faTrash} performOperation={retireGovernance} hideModal={() => setDisplayedModal(Modal.None)}>
                    <Text>{`Once this ${getHumanReadableGovernanceType(currentVersion.type)} is retired, it cannot be made active again.`}</Text>
                </ConfirmationModal>
            )}
            {displayedTextBasedGovernance && <ViewGovernanceLibraryDefinitionModal {...viewGovernanceLibraryDefinitionModalProps(displayedTextBasedGovernance)} />}
            {displayedVersionAssociatedControls && <AssociatedControlsModal {...associatedControlModalProps(displayedVersionAssociatedControls)} />}
            <Page
                headerBreadcrumb={getBreadcrumb()}
                headerTitle={currentVersion.title}
                headerButtons={
                    currentVersion.status === GovernanceStatus.ACTIVE && (
                        <>
                            <Button variant="danger" fontAwesomeImage={faTrash} onClick={() => setDisplayedModal(Modal.RetireGovernance)}>
                                Retire
                            </Button>
                            <Link variant="primaryButton" fontAwesomeImage={faPlus} to={`/${GOVERNANCE}/${governanceId}/${CREATE}`}>
                                Add New Version
                            </Link>
                        </>
                    )
                }
                body={[
                    {
                        title: 'Versions',
                        content: (
                            <>
                                <div className={styles.accordionHeaderContainer}>
                                    <div className={styles.accordionHeaderTitle}>
                                        <Text variant="Text3" noStyles>
                                            TITLE
                                        </Text>
                                    </div>
                                    <div className={styles.accordionHeaderOwner}>
                                        <Text variant="Text3" noStyles>
                                            OWNER
                                        </Text>
                                    </div>
                                    <div className={styles.accordionHeaderControls}>
                                        <Text variant="Text3" noStyles>
                                            CONTROLS
                                        </Text>
                                    </div>
                                    <div className={styles.accordionHeaderEffectiveDate}>
                                        <Text variant="Text3" noStyles>
                                            EFFECTIVE DATE
                                        </Text>
                                    </div>
                                    <div className={styles.accordionHeaderAction} />
                                </div>
                                {versions.map(accordionForVersion)}
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default ManageGovernance;
