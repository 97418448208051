import { DocumentApi } from 'Api/Document/DocumentApi';
import { useUsers } from 'Components/Context/UsersContext';
import { FieldLabel } from 'Components/FieldLabel/FieldLabel';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import Text from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedHyperlink/UserDefinedHyperlink';
import { ISSUES_EXCEPTIONS_REFERENCE, ISSUE_SOURCE } from 'Config/Tooltips';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { IssueResponse, IssueStatus, titleCaseIssuePriority, titleCaseIssueStatus } from 'Models/Issues';
import styles from 'Styles/DetailsPage.module.css';

interface IssueDetailsSnapshotProps {
    issue: IssueResponse;
    documentApi: DocumentApi;
}

/**
 * Renders details for an issue (current or historical), minus the impacted controls or impacted vendor.
 * Used in the "main" area of the Issue Details page, and in historical modals on the same page.
 */
export const IssueDetailsSnapshot = (props: IssueDetailsSnapshotProps) => {
    const { users } = useUsers();

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsGridSection}>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Status" />
                        <Text variant="Text2">{titleCaseIssueStatus(props.issue.status)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Owner" />
                        <Text>{getUserNameFromSubject(props.issue.owner_subject, users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Created" />
                        <Text>{`${iso8601ToUsDateShort(props.issue.created_timestamp)} by ${getUserNameFromSubject(props.issue.created_by, users, UserNameFormat.FIRST_SPACE_LAST)}`}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Priority" />
                        <Text variant="Text2">{titleCaseIssuePriority(props.issue.priority)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Delegates" />
                        <Text>{props.issue.delegates.length === 0 ? '-' : props.issue.delegates.map((delegate) => getUserNameFromSubject(delegate, users, UserNameFormat.FIRST_SPACE_LAST)).join(', ')}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Source" tooltipText={ISSUE_SOURCE} />
                        <Text>{props.issue.source}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Due Date" />
                        <Text variant="Text2">{iso8601ToUsDateShort(props.issue.due_date)}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Reference" tooltipText={ISSUES_EXCEPTIONS_REFERENCE} />
                <UserDefinedReference reference={props.issue.reference} />
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Description" />
                <Text>{props.issue.description}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Risk Assessment" />
                <Text>{props.issue.risk_assessment}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Remediation Plan" />
                <Text>{props.issue.remediation_plan}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Files" />
                {props.issue.files.length === 0 ? <Text>-</Text> : [...props.issue.files].sort((a, b) => (a.filename > b.filename ? 1 : -1)).map((file) => <UploadedFileAndState key={file.file_id} file={file} documentApi={props.documentApi} />)}
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Closure Statement" />
                <Text>{props.issue.status === IssueStatus.CLOSED ? props.issue.closure_statement : '-'}</Text>
            </div>
        </div>
    );
};
