/*
	Tenable.tsx -- Modal for configuring the Tenable integration.
*/

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

interface TenableState {
    isSubmitting: boolean;
    isDeleted: boolean;
    isDeleting: boolean;
    successMessage?: string;
    failureMessage?: string;
    accessKey?: string;
    secretKey?: string;
}

export class Tenable extends Component<ConfigureIntegrationProps, TenableState> {
    constructor(props: ConfigureIntegrationProps) {
        super(props);

        this.state = {
            isDeleted: false,
            isDeleting: false,
            isSubmitting: false,
        };
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ ...this.state, [event.currentTarget.name]: event.currentTarget.value });
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        this.setState({ isSubmitting: true, successMessage: undefined, failureMessage: undefined });

        try {
            this.validateForm(this.state.accessKey, this.state.secretKey);
            await this.props.externalIntegrationsApi.setExternalIntegration(IntegrationName.TENABLE, {
                access_key: this.state.accessKey!,
                secret_key: this.state.secretKey!,
            });
            this.setState({ successMessage: 'Tenable integration configured.' });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    validateForm = (accessKey?: string, secretKey?: string): void => {
        if (!accessKey || accessKey.length === 0) {
            throw new ValidationError('Invalid access key.');
        }
        if (!secretKey || secretKey.length === 0) {
            throw new ValidationError('Invalid secret key.');
        }
    };

    handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        this.setState({ isDeleting: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.TENABLE);
            this.setState({ successMessage: 'Tenable integration deleted.', isDeleted: true });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isDeleting: false });
        }
    };

    handleRequestError = (err: Error): void => {
        this.setState({ failureMessage: err.message });
    };

    hideModal = (): void => {
        if (!this.state.isSubmitting) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <ModalHeader text="Configure Tenable Integration" />
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="accessKey" formFieldLabel="Access Key" required={true} tooltip="The API access key provided by Tenable." invalidMessage="Please enter a valid Tenable API access key." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="secretKey" formFieldLabel="Secret Key" formFieldType="password" required={true} tooltip="The API secret key provided by Tenable." invalidMessage="Please enter a valid Tenable API secret key." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.buttonRowContainer}>
                            <Button variant="danger" disabled={this.state.isDeleted || this.state.isDeleting} onClick={this.handleDelete} loadingText={'Deleting...'} isLoading={this.state.isDeleting} fontAwesomeImage={faTrash}>
                                DELETE
                            </Button>
                            <div className={styles.buttonsRightContainer}>
                                <div className={styles.buttonPadding}>
                                    <Button variant="secondary" onClick={this.props.hideModal} disabled={this.state.isSubmitting || this.state.isDeleting} fontAwesomeImage={faTimes}>
                                        CLOSE
                                    </Button>
                                </div>
                                <Button variant="submit" disabled={this.state.isDeleted || this.state.isDeleting} isLoading={this.state.isSubmitting} loadingText="Saving...">
                                    SAVE
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}
