import { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, OptionProps, Props, components } from 'react-select';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { getUserNameFromUser, sortFormattedUsers } from 'Helpers/UserUtils';
import { Status, UserResponse } from 'Models/User';

import UserOption, { UserOptionProps } from './UserOption/UserOption';

interface FormFieldNonClearableUserSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUserSelected: (value: UserResponse, formFieldId: string) => void;
    selectedUser?: UserResponse;
    disabled?: boolean;
    users: UserResponse[];
    includeInactive?: boolean;
    isClearable?: false;
}

interface FormFieldClearableUserSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUserSelected: (value: UserResponse | undefined, formFieldId: string) => void;
    selectedUser?: UserResponse;
    disabled?: boolean;
    users: UserResponse[];
    includeInactive?: boolean;
    isClearable: true;
}

export type FormFieldUserSelectProps = FormFieldNonClearableUserSelectProps | FormFieldClearableUserSelectProps;

class UserResponseOption {
    value: string;
    label: string;
    user: UserResponse;

    constructor(user: UserResponse) {
        this.value = user.cognito_subject;
        this.label = getUserNameFromUser(user);
        this.user = user;
    }
}

export default class FormFieldUserSelect extends Component<FormFieldUserSelectProps> {
    mapUserToUserOption = (user: UserResponse): UserResponseOption => new UserResponseOption(user);

    filterAndFormatUsers = (): UserResponseOption[] => {
        let users = [...this.props.users];

        if (!this.props.includeInactive) {
            users = users.filter((user) => user.status === Status.ACTIVE);
        }

        return sortFormattedUsers(users).map(this.mapUserToUserOption);
    };

    handleChange = (value: OnChangeValue<UserResponseOption, false>): void => {
        if (value && value instanceof UserResponseOption) {
            this.props.onUserSelected(value.user, this.props.formFieldId);
        } else if (this.props.isClearable) {
            this.props.onUserSelected(undefined, this.props.formFieldId);
        }
    };

    userOption = (props: OptionProps<UserResponseOption, false>): JSX.Element => {
        const userOptionProps: UserOptionProps = {
            user: props.data.user,
        };
        return (
            <components.Option {...props}>
                <UserOption {...userOptionProps} />
            </components.Option>
        );
    };

    render(): JSX.Element {
        const selectProps: Props<UserResponseOption, false> = {
            options: this.filterAndFormatUsers(),
            onChange: this.handleChange,
            value: this.props.selectedUser ? this.mapUserToUserOption(this.props.selectedUser) : undefined,
            isDisabled: this.props.disabled,
            inputId: this.props.formFieldId,
            isClearable: this.props.isClearable,
        };
        return (
            <FormGroup controlId={this.props.formFieldId} className={styles.formField}>
                <FormLabel required={this.props.isRequiredField}>{this.props.formFieldLabel}</FormLabel>
                {this.props.tooltip && <FormFieldTooltip text={this.props.tooltip} />}
                <Select
                    {...selectProps}
                    components={{ Option: this.userOption }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 9,
                        colors: {
                            ...theme.colors,
                            primary25: '#05263717',
                            text: 'var(--hps-blue)',
                            primary: 'var(--hps-blue)',
                        },
                    })}
                    styles={{
                        ...styles,
                        control: (base) => ({
                            ...base,
                            borderColor: '#053c591a',
                            color: 'var(--hps-blue)',
                        }),
                        multiValueRemove: (styles, _) => ({
                            ...styles,
                            ':hover': {
                                color: 'var(--hps-blue)',
                            },
                        }),
                        option: (styles, { isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected ? '#05263717' : '',
                            color: isSelected ? 'var(--hps-blue)' : '',
                        }),
                        input: (base) => ({
                            ...base,
                            color: 'var(--hps-blue)',
                        }),
                    }}
                />
                <FormControl.Feedback type="invalid">{this.props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
            </FormGroup>
        );
    }
}
