import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import IconButton from 'Components/Buttons/IconButton';
import UserFilter from 'Components/Filters/UserFilter/UserFilter';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { VendorListingSortProperty } from 'Models/TPRM';
import { Filter, GroupOptionType, OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './VendorListingBodyToolbar.module.css';
import { VendorListingView } from '../VendorListing';

export interface VendorListingBodyToolbarProps {
    switchListingView: (activeVendorListingView: VendorListingView) => void;
    activeVendorListingView: VendorListingView;
    defaultSelectedOptions?: GroupOptionType[];
    filterVendors: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentlySortedBy: string;
    users: UserResponse[];
}

const VendorListingSortOptions: OptionType[] = [
    {
        label: 'Name',
        value: VendorListingSortProperty.NAME,
    },
    {
        label: 'Date Created',
        value: VendorListingSortProperty.CREATED_TIME,
    },
    {
        label: 'Vendor Manager',
        value: VendorListingSortProperty.VENDOR_MANAGER_USER_ID,
    },
];

export default class VendorListingBodyToolbar extends Component<VendorListingBodyToolbarProps> {
    handleSortSelectionChange = (value: ChangeEventType): void => {
        const property = value as VendorListingSortProperty;
        if (property) {
            this.props.sortCardsBy(property, SortDirection.ASC);
        }
    };

    render(): JSX.Element {
        return (
            <div className={styles.vendorListingFilter}>
                <div className={styles.filter}>
                    {this.props.activeVendorListingView === VendorListingView.GRID && (
                        <div className={styles.sortSelect}>
                            <FormFieldSelect options={VendorListingSortOptions} handleChange={this.handleSortSelectionChange} formFieldId="sortVendorsBy" formFieldLabel="Sort" selectedOption={this.props.currentlySortedBy} />
                        </div>
                    )}
                    <div className={styles.filterSelect}>
                        <UserFilter filterLabel="Filter by Vendor Manager" onUsersSelected={this.props.filterVendors} users={this.props.users} userFilterId="vendor_manager_user_id" formFieldId="vendorManagerFilter" />
                    </div>
                </div>
                <div className={styles.tableAndGridButtonPanel}>
                    <IconButton aria-label="Switch to Vendor Grid View" onClick={() => this.props.switchListingView(VendorListingView.GRID)} fontAwesomeImage={faThLarge} disabled={this.props.activeVendorListingView === VendorListingView.GRID} />
                    <IconButton aria-label="Switch to Vendor List View" onClick={() => this.props.switchListingView(VendorListingView.LIST)} fontAwesomeImage={faBars} disabled={this.props.activeVendorListingView === VendorListingView.LIST} />
                </div>
            </div>
        );
    }
}
