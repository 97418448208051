/*
	Qualys.tsx -- Modal for configuring the Qualys integration.
*/

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

interface QualysState {
    isSubmitting: boolean;
    isDeleted: boolean;
    isDeleting: boolean;
    successMessage?: string;
    failureMessage?: string;
    username?: string;
    password?: string;
    platform?: string;
}

export class Qualys extends Component<ConfigureIntegrationProps, QualysState> {
    constructor(props: ConfigureIntegrationProps) {
        super(props);

        this.state = {
            isDeleted: false,
            isDeleting: false,
            isSubmitting: false,
        };
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ ...this.state, [event.currentTarget.name]: event.currentTarget.value });
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        this.setState({ isSubmitting: true, successMessage: undefined, failureMessage: undefined });

        try {
            this.validateForm(this.state.username, this.state.password, this.state.platform);
            await this.props.externalIntegrationsApi.setExternalIntegration(IntegrationName.QUALYS, {
                username: this.state.username!,
                password: this.state.password!,
                platform: this.state.platform!,
            });
            this.setState({ successMessage: 'Qualys integration configured.' });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    validateForm = (username?: string, password?: string, platform?: string): void => {
        if (!username || username.length === 0) {
            throw new ValidationError('Invalid username.');
        }
        if (!password || password.length === 0) {
            throw new ValidationError('Invalid password.');
        }
        if (!platform || platform.length === 0) {
            throw new ValidationError('Invalid platform.');
        }
    };

    handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        this.setState({ isDeleting: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.QUALYS);
            this.setState({ successMessage: 'Qualys integration deleted.', isDeleted: true });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isDeleting: false });
        }
    };

    handleRequestError = (err: Error): void => {
        this.setState({ failureMessage: err.message });
    };

    hideModal = (): void => {
        if (!this.state.isSubmitting) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <ModalHeader text="Configure Qualys Integration" />
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="username" formFieldLabel="Username" required={true} tooltip="The API username provided by Qualys." invalidMessage="Please enter a valid Qualys API username." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="password" formFieldLabel="Password" formFieldType="password" required={true} tooltip="The API password provided by Qualys." invalidMessage="Please enter a valid Qualys API password." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="platform" formFieldLabel="Platform" required={true} placeholder="Example: US1" tooltip="The Qualys platform your account is hosted on. See: https://www.qualys.com/platform-identification/" invalidMessage="Please enter a valid Qualys platform." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.buttonRowContainer}>
                            <Button variant="danger" disabled={this.state.isDeleted || this.state.isDeleting} onClick={this.handleDelete} loadingText={'Deleting...'} isLoading={this.state.isDeleting} fontAwesomeImage={faTrash}>
                                DELETE
                            </Button>
                            <div className={styles.buttonsRightContainer}>
                                <div className={styles.buttonPadding}>
                                    <Button variant="secondary" onClick={this.props.hideModal} disabled={this.state.isSubmitting || this.state.isDeleting} fontAwesomeImage={faTimes}>
                                        CLOSE
                                    </Button>
                                </div>
                                <Button variant="submit" disabled={this.state.isDeleted || this.state.isDeleting} isLoading={this.state.isSubmitting} loadingText="Saving...">
                                    SAVE
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}
