/*
	Placeholder.tsx -- A placeholder for when page content is loading
*/

import { Component } from 'react';
// The following 2 lines are needed because this package never created a type declaration file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Lottie from 'react-lottie-player';

import animationData from 'Lottie/loading.json';

import styles from './Placeholder.module.css';

export default class Placeholder extends Component {
    render(): JSX.Element {
        return (
            <div className={styles.placeholderBackground}>
                <Lottie loop animationData={animationData} play className={styles.animation} />
            </div>
        );
    }
}
