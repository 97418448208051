import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import Text from 'Components/Text/Text';
import { Limit, MetricLimit } from 'Models/Limits';

import styles from './MetricLimitsTable.module.css';
import MetricLimitsTableRow, { MetricLimitsTableRowProps } from './MetricLimitsTableRow.tsx/MetricLimitsTableRow';

export interface MetricLimitsTableProps {
    limitResponseList: MetricLimit[];
    displayDeleteLimitModal: (deleteLimitId: string, deleteLimitTitle: string) => void;
    displayModifyLimitModal: (modifyLimit: Limit) => void;
    alertsLimitsApi: AlertsLimitsApi;
    addLimitsButton?: JSX.Element;
}

export const MetricLimitsTable = (props: MetricLimitsTableProps) => {
    const tableRow = (limit: MetricLimit): JSX.Element => {
        const limitsTableRowProps: MetricLimitsTableRowProps = {
            limit: limit,
            displayDeleteLimitModal: props.displayDeleteLimitModal,
            displayModifyLimitModal: props.displayModifyLimitModal,
            alertsLimitsApi: props.alertsLimitsApi,
        };
        return <MetricLimitsTableRow key={limit.id} {...limitsTableRowProps} />;
    };
    return (
        <div className={styles.tableContainer}>
            <div className={styles.pageSectionHeader}>
                <Text variant="Header2">Metrics</Text>
                {props.addLimitsButton}
            </div>
            <div className={styles.headerContainer}>
                <div className={styles.toggleHeader}></div>
                <div className={styles.titleContainer}>
                    <Text>TITLE</Text>
                </div>
                <div className={styles.limitPreferenceContainer}>
                    <Text>LIMIT</Text>
                </div>
                <div className={styles.limitPreferenceContainer}>
                    <Text>ALERT PREFERENCE</Text>
                </div>
                <div className={styles.overflowHeader}></div>
            </div>
            {props.limitResponseList.map(tableRow)}
        </div>
    );
};
