import { faCog, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Button, Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import { RiskCells } from 'Components/RiskRegister/RiskCells/RiskCells';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { TagListing } from 'Components/Tags/TagListing/TagListing';
import Text from 'Components/Text/Text';
import { CONFIGURATION, RISKS, RISK_REGISTER } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { RiskRatingHistoryResponse, RiskResponse } from 'Models/RiskRegister';

import { MitigatingControlsListing } from './MitigatingControlsListing/MitigatingControlsListing';
import styles from './RiskDetails.module.css';
import { RiskRatingHistoryTab } from './RiskRatingHistoryTab/RiskRatingHistoryTab';
import { TreatmentPlanListing } from './TreatmentPlanListing/TreatmentPlanListing';
import { UpdateRiskScoresModal } from './UpdateRiskScoresModal/UpdateRiskScoresModal';

export interface RiskDetailsLocationState {
    assessmentTimestamp: string;
}

export interface UrlParams {
    risk_id: string;
}

export interface RiskDetailsProps {
    riskRegisterApi: RiskRegisterApi;
    tagsApi: TagsApi;
}

export enum RiskTab {
    MITIGATING_CONTROLS = 'controls',
    TREATMENT_PLAN = 'treatment_plan',
    HISTORY = 'history',
}

enum Modals {
    NONE,
    SET_RISK_SCORES,
}

export const RiskDetails = (props: RiskDetailsProps): JSX.Element => {
    const params = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();
    const navigate = useNavigate();

    const { users } = useUsers();
    const [riskDetail, setRiskDetail] = useState<RiskResponse>();
    const [riskRatingHistory, setRiskRatingHistory] = useState<RiskRatingHistoryResponse[]>();
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);
    const [riskDetailError, setRiskDetailError] = useState<string>();

    /**
     * If the user was linked here from the View Risk Assessment page (i.e. they clicked on the title of a risk), then we show them the risk rating history of the risk.
     * The best-fit history--the most recent history that was recorded before the assessment was submitted--is auto-expanded.
     */
    const timestampOfHistoryToAutoExpand = (() => {
        if (!location.state || !riskRatingHistory) {
            return undefined;
        }

        const assessmentTimestamp = (location.state as RiskDetailsLocationState).assessmentTimestamp;

        // `find` can be used because the histories returned from the backend are in reverse chronological order.
        return riskRatingHistory.find((history) => history.timestamp < assessmentTimestamp)?.timestamp;
    })();

    const activeHash = (() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (Object.values(RiskTab).includes(strippedHash as RiskTab)) {
                return strippedHash;
            }
        }
        return RiskTab.MITIGATING_CONTROLS;
    })();

    const onTabSelect = (key?: string) => {
        if (key) {
            navigate(`${location.pathname}#${key}`, { replace: true });
        }
    };

    const getRiskDetailsAndHistory = useCallback(async (): Promise<void> => {
        try {
            const [riskDetailResponse, ratingResponse] = await Promise.all([props.riskRegisterApi.getRiskDetails(params.risk_id), props.riskRegisterApi.getRiskRatingHistory(params.risk_id)]);

            setRiskDetail(riskDetailResponse.data);
            setRiskRatingHistory(ratingResponse.data);
        } catch (err) {
            setRiskDetailError(err.message);
        }
    }, [params.risk_id, props.riskRegisterApi]);

    useEffect(() => {
        getRiskDetailsAndHistory();
    }, [getRiskDetailsAndHistory]);

    if (riskDetailError) {
        return <Text>{riskDetailError}</Text>;
    } else if (getDisplayableTagsState.type === 'failure') {
        return <Text>{getDisplayableTagsState.message}</Text>;
    } else if (riskDetail && riskRatingHistory && getDisplayableTagsState.type === 'success') {
        return (
            <>
                {modalState === Modals.SET_RISK_SCORES && <UpdateRiskScoresModal risk={riskDetail} riskRegisterApi={props.riskRegisterApi} hideModal={() => setModalState(Modals.NONE)} onRiskScoresUpdated={getRiskDetailsAndHistory} />}
                <PageBackground color="blueMountains">
                    <PageContent>
                        <div className={styles.riskDetailHeader}>
                            <Breadcrumb textColor="blue">
                                <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}`}>Risk Listing</BreadcrumbLink>
                                <BreadcrumbText>{riskDetail.title}</BreadcrumbText>
                            </Breadcrumb>
                            <div className={styles.headerButtons}>
                                <Link variant="primaryButton" to={`/${RISK_REGISTER}/${RISKS}/${riskDetail.id}/${CONFIGURATION}`} fontAwesomeImage={faCog}>
                                    Manage Risk
                                </Link>
                                <Button variant="primary" onClick={() => setModalState(Modals.SET_RISK_SCORES)} fontAwesomeImage={faEdit}>
                                    Update Risk Scores
                                </Button>
                            </div>
                        </div>
                        <Text color="white" variant="Header1" noStyles>
                            {riskDetail.title}
                        </Text>
                        <div className={styles.riskDescription}>
                            <Text color="darkGray" variant="Header2">
                                {riskDetail.description}
                            </Text>
                        </div>
                        <div className={styles.riskDetails}>
                            <PageCell variant="transparentBlue">
                                <Text color="white" variant="Header2">
                                    Risk Details
                                </Text>
                                <div className={styles.riskDetailCallouts}>
                                    <div className={styles.riskCategory}>
                                        <Text noStyles color="darkGray" variant="Header4">
                                            RISK CATEGORY
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {riskDetail.category.title}
                                        </Text>
                                    </div>
                                    <div className={styles.riskDetailStrategy}>
                                        <Text noStyles color="darkGray" variant="Header4">
                                            RISK STRATEGY
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {riskDetail.strategy ? riskDetail.strategy.title : '-'}
                                        </Text>
                                    </div>
                                    <div className={styles.riskOwner}>
                                        <Text noStyles color="darkGray" variant="Header4">
                                            RISK OWNER
                                        </Text>
                                        <Text noStyles variant="Text1" color="white">
                                            {riskDetail.owner ? getUserNameFromSubject(riskDetail.owner, users, UserNameFormat.FIRST_SPACE_LAST) : '-'}
                                        </Text>
                                    </div>
                                </div>
                                <div className={styles.riskTags}>
                                    <Text noStyles color="darkGray" variant="Header4">
                                        RISK TAGS
                                    </Text>
                                    {riskDetail.tags.length > 0 ? (
                                        <div className={styles.tagListing}>
                                            <TagListing tags={getDisplayableTagsState.data(riskDetail.tags)} showAll />
                                        </div>
                                    ) : (
                                        <Text noStyles variant="Text1" color="white">
                                            -
                                        </Text>
                                    )}
                                </div>
                            </PageCell>
                        </div>
                        <div className={styles.riskValues}>
                            <RiskCells risk={riskDetail} riskRatingHistory={riskRatingHistory} />
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            <PrimaryTabs onSelect={onTabSelect} defaultActiveTab={activeHash} removePadding transparent>
                                <Tab eventKey={RiskTab.MITIGATING_CONTROLS} title="Controls">
                                    <MitigatingControlsListing averageEffectiveness={riskDetail.control_environment_effectiveness} riskControlMapping={riskDetail.controls} />
                                </Tab>
                                <Tab eventKey={RiskTab.TREATMENT_PLAN} title="Treatment Plan">
                                    <TreatmentPlanListing riskId={riskDetail.id} actions={riskDetail.associated_actions} treatmentPlanStatus={riskDetail.treatment_plan_status} treatmentPlanDescription={riskDetail.treatment_plan_description} />
                                </Tab>
                                <Tab eventKey={RiskTab.HISTORY} title="History">
                                    <RiskRatingHistoryTab histories={riskRatingHistory} timestampOfHistoryToAutoExpand={timestampOfHistoryToAutoExpand} />
                                </Tab>
                            </PrimaryTabs>
                        </PageCell>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else return <Placeholder />;
};
