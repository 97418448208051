import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import { _NUMBER_OF_DAYS_OF_ALERTS } from 'Models/Alerts';

import { GenericTooltip, IconColor } from './GenericTooltip';

export enum AlertTooltipType {
    FRAMEWORK = 'FRAMEWORK',
    GROUP = 'GROUP',
    CONTROL = 'CONTROL',
}

interface AlertsTooltipProps {
    alertTooltipType: AlertTooltipType;
    entityName: string;
    iconColor?: IconColor;
}

export class AlertsTooltip extends Component<AlertsTooltipProps> {
    renderText = (): string => {
        const text = `Alerts in the past ${_NUMBER_OF_DAYS_OF_ALERTS} days for ${this.props.entityName}`;

        switch (this.props.alertTooltipType) {
            case AlertTooltipType.FRAMEWORK:
                return `${text}. Does not include alerts for child groups/controls.`;
            case AlertTooltipType.GROUP:
                return `${text}. Does not include alerts for child controls.`;
            default:
                return `${text}.`;
        }
    };

    render(): JSX.Element {
        return <GenericTooltip text={this.renderText()} fontAwesomeIcon={faQuestionCircle} iconColor={this.props.iconColor ? this.props.iconColor : IconColor.WHITE} />;
    }
}
