import { faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { UserNameFormat, getUserNameFromUser } from 'Helpers/UserUtils';
import { UserResponse } from 'Models/User';

import styles from './ReactivateUserModal.module.css';

export interface ReactivateUserModalProps {
    hideModal: () => void;
    userSelectedForReactivation: UserResponse;
    usersApi: UsersApi;
    userReactivated: () => void;
}

export interface ReactivateUserModalState {
    successMessage?: string;
    failureMessage?: string;
    isReactivating: boolean;
}

export default class ReactivateUserModal extends Component<ReactivateUserModalProps, ReactivateUserModalState> {
    constructor(props: ReactivateUserModalProps) {
        super(props);

        this.state = {
            isReactivating: false,
        };
    }

    reactivateUser = async (): Promise<void> => {
        this.setState({ isReactivating: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.usersApi.reactivateUser(this.props.userSelectedForReactivation.cognito_subject);
            this.setState({ successMessage: 'User reactivated.' });
            this.props.userReactivated();
        } catch (error) {
            this.handleRequestError(error);
        } finally {
            this.setState({ isReactivating: false });
        }
    };

    handleRequestError = (error: Error): void => {
        this.setState({ failureMessage: error.message, successMessage: undefined });
    };

    hideModal = (): void => {
        if (!this.state.isReactivating) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <ModalHeader text="Reactivate" />
                    <div className={styles.reactivateText}>Reactivating a user will allow the user to login to SummIT and receive notifications.</div>
                    <div className={styles.reactivateText}>Are you sure you want to reactivate: {getUserNameFromUser(this.props.userSelectedForReactivation, UserNameFormat.FIRST_SPACE_LAST)}?</div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={this.hideModal} disabled={this.state.isReactivating} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="danger" onClick={() => this.reactivateUser()} fontAwesomeImage={faUser} loadingText={'Reactivating...'} isLoading={this.state.isReactivating} disabled={this.state.successMessage ? true : false}>
                            REACTIVATE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
