import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Button } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { FileDragAndDropSingleMode, FileDragAndDropSingleModeProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldTextArea from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import FormFieldUserSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping } from 'Components/MultipleControlMapping/MultipleControlMapping';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { GOVERNANCE } from 'Config/Paths';
import { getFrameworkGroupControlParts } from 'Helpers/ControlFormatter/ControlFormatter';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { submitRequestWithFile } from 'Helpers/FileUtils';
import { validateUrl } from 'Helpers/InputValidation';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { useFileDragAndDropSingleMode } from 'Hooks/FileDragAndDrop';
import { CreateDocumentBasedGovernanceVersionRequest, CreateGovernanceVersionRequest, CreateTextBasedGovernanceVersionRequest, CreateUrlBasedGovernanceVersionRequest, GovernanceContentType, GovernanceType, GovernanceValuesSelectOptions, GovernanceVersion } from 'Models/Governance';
import { OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './AddGovernanceVersion.module.css';

export interface AddGovernanceVersionProps {
    documentApi: DocumentApi;
    controlsApi: ControlsApi;
    governanceApi: GovernanceApi;
}

export interface UrlParams {
    governanceId?: string;
}

interface FormFieldsState {
    effective_date: Date;
    associated_controls: string[];
    content_type: GovernanceContentType;
    type: GovernanceType;

    title?: string;
    owner?: UserResponse;
    changelog?: string;
    external_url?: string;
    text?: string;
}

interface FormState {
    isUpdatingGovernance: boolean;
    successMessage?: string;
    failureMessage?: string;
}

/**
 * Returns the start of the current date without the time, so that policies are considered effective at the exact start of today.
 */
const getStartOfToday = () => {
    return moment().startOf('day').toDate();
};

export const GovernanceContentTypeSelectOptions: OptionType[] = [
    {
        label: 'Document',
        value: GovernanceContentType.DOCUMENT,
    },
    {
        label: 'Text',
        value: GovernanceContentType.TEXT,
    },
    {
        label: 'External URL',
        value: GovernanceContentType.EXTERNAL_URL,
    },
];

/**
 * Effectively functions as a "manage" page for a specific "governance"--a policy, standard, or procedure.
 * Unlike most entities in the app, governances are versioned and immutable, so this page is used to create a new version for a specific governance: if the governance doesn't exist yet, then an initial version is created; otherwise, a new version of the existing governance is created.
 */
export const AddGovernanceVersion = (props: AddGovernanceVersionProps): JSX.Element => {
    const { governanceId } = useParams<keyof UrlParams>() as UrlParams;
    const versionExistsAlready = governanceId !== undefined;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const controlIdQueryParam = query.get('controlId');

    const { users } = useUsers();
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState<boolean>(false);
    const [formState, setFormState] = useState<FormState>({ isUpdatingGovernance: false });
    const [file, onSelectFile, onRemoveFile] = useFileDragAndDropSingleMode();

    // If the API returns an effective version, this will be a GovernanceVersion; if the API returns a 204, this will be null. Until the API returns a response, this will be undefined.
    const [initialEffectiveVersion, setInitialEffectiveVersion] = useState<GovernanceVersion | null | undefined>(versionExistsAlready ? undefined : null);

    const [createdVersionId, setCreatedVersionId] = useState<string>();
    const [initialAssociatedControls, setInitialAssociatedControls] = useState<string[]>([]);
    const [formFieldState, setFormFieldState] = useState<FormFieldsState>({
        effective_date: getStartOfToday(),
        associated_controls: controlIdQueryParam ? [controlIdQueryParam] : initialAssociatedControls,
        content_type: GovernanceContentType.DOCUMENT,
        type: GovernanceType.POLICY,
    });
    const [controlMappingItems, controlMappingItemsError, controlNavigatedFrom] = useControlMappingItems(props.controlsApi, controlIdQueryParam ? controlIdQueryParam : undefined);

    useEffect(() => {
        const getActiveVersion = async (): Promise<void> => {
            if (!versionExistsAlready) {
                setInitialEffectiveVersion(null);
                return;
            }

            try {
                const effectiveVersion = (await props.governanceApi.getEffectiveVersionForGovernance(governanceId)).data;

                if (!effectiveVersion) {
                    setInitialEffectiveVersion(null);
                    return;
                }

                if (effectiveVersion.associated_controls) {
                    setInitialAssociatedControls(effectiveVersion.associated_controls.map((control) => control.identifier));
                }

                setInitialEffectiveVersion(effectiveVersion);
                setFormFieldState({
                    effective_date: getStartOfToday(),
                    associated_controls: effectiveVersion.associated_controls.map((control) => control.identifier),

                    title: effectiveVersion.title,
                    type: effectiveVersion.type,
                    owner: users.find((user) => user.cognito_subject === effectiveVersion.owner_user_id),
                    content_type: effectiveVersion.content_type,
                    external_url: effectiveVersion.content_type === GovernanceContentType.EXTERNAL_URL ? effectiveVersion.external_url : undefined,
                    text: effectiveVersion.content_type === GovernanceContentType.TEXT ? effectiveVersion.text : undefined,
                });
            } catch (error) {
                setLoadingErrorOccurred(true);
            }
        };

        getActiveVersion();
    }, [governanceId, props.documentApi, props.governanceApi, users, versionExistsAlready]);

    const governanceTypeText = (() => {
        switch (formFieldState.type) {
            case GovernanceType.POLICY:
                return 'Policy';
            case GovernanceType.STANDARD:
                return 'Standard';
            case GovernanceType.PROCEDURE:
                return 'Procedure';
        }
    })();

    const submitRequest = async () => {
        // Define a helper function for creating the new version, which will use either the "create initial version" or the "add new version" endpoint.
        const createNewVersion = async (request: CreateGovernanceVersionRequest) => {
            if (versionExistsAlready) {
                return (await props.governanceApi.addGovernanceVersion(governanceId, request)).data;
            } else {
                return (await props.governanceApi.createInitialGovernanceVersion(request)).data;
            }
        };

        try {
            setFormState({ isUpdatingGovernance: true });

            // Perform content-type-agnostic validation.
            if (!formFieldState.title) {
                throw Error('Title is required.');
            }

            if (!formFieldState.owner) {
                throw Error('Owner is required.');
            }

            if (!formFieldState.effective_date) {
                throw Error('Effective date is required.');
            }

            if (!formFieldState.changelog) {
                throw Error('Changelog is required.');
            }

            const baseRequest = {
                title: formFieldState.title,
                type: formFieldState.type,
                owner_user_id: formFieldState.owner.cognito_subject,
                content_type: formFieldState.content_type,
                effective_date: jsDateToIso8601(formFieldState.effective_date),
                changelog: formFieldState.changelog,
                associated_controls: formFieldState.associated_controls,
                external_url: formFieldState.content_type === GovernanceContentType.EXTERNAL_URL ? formFieldState.external_url : undefined,
            };

            // Perform content-type-specific validation and submit the request.
            let createdVersionId: string;
            switch (formFieldState.content_type) {
                case GovernanceContentType.DOCUMENT:
                    if (!versionExistsAlready && !file) {
                        throw Error(`A file is required for a document-based ${governanceTypeText}.`);
                    }

                    const documentRequest: CreateDocumentBasedGovernanceVersionRequest = {
                        ...baseRequest,
                        content_type: GovernanceContentType.DOCUMENT,
                    };

                    const keepExistingContent = initialEffectiveVersion?.content_type === GovernanceContentType.DOCUMENT && initialEffectiveVersion.file !== undefined && !file;

                    createdVersionId = await (async () => {
                        if (keepExistingContent) {
                            return await createNewVersion(documentRequest);
                        } else {
                            return await submitRequestWithFile(props.documentApi, { file: file! }, async (newDocumentation) => {
                                documentRequest.file_to_be_uploaded = newDocumentation;
                                return await createNewVersion(documentRequest);
                            });
                        }
                    })();
                    break;
                case GovernanceContentType.TEXT:
                    if (!formFieldState.text) {
                        throw Error(`A definition is required for a text-based ${governanceTypeText}.`);
                    }

                    const textRequest: CreateTextBasedGovernanceVersionRequest = {
                        ...baseRequest,
                        content_type: GovernanceContentType.TEXT,
                        text: formFieldState.text,
                    };

                    createdVersionId = await createNewVersion(textRequest);
                    break;
                case GovernanceContentType.EXTERNAL_URL:
                    if (!formFieldState.external_url) {
                        throw Error(`A link is required for a URL-based ${governanceTypeText}.`);
                    }
                    const validationResult = validateUrl(formFieldState.external_url);
                    if (validationResult['valid'] !== true) {
                        throw Error(validationResult['message']);
                    }

                    const urlRequest: CreateUrlBasedGovernanceVersionRequest = {
                        ...baseRequest,
                        content_type: GovernanceContentType.EXTERNAL_URL,
                        external_url: formFieldState.external_url,
                    };

                    createdVersionId = await createNewVersion(urlRequest);
                    break;
            }

            // Record the ID of the new governance, so that the user can be linked to the Manage Governance page (and so this component knows to show a success message).
            setCreatedVersionId(createdVersionId);
            setFormState({ isUpdatingGovernance: false, successMessage: `${governanceTypeText} version saved.` });
        } catch (error) {
            setFormState({ failureMessage: error.message, isUpdatingGovernance: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldState({ ...formFieldState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, [formFieldId]: value });
    };

    const handleSelectOwnerChange = (user: UserResponse, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, owner: user });
    };

    const handleChangeEffectiveDate = (date: Date): void => {
        setFormFieldState({ ...formFieldState, effective_date: date });
    };

    const handleAssociatedControlChange = (controls: string[]): void => {
        setFormFieldState({ ...formFieldState, associated_controls: controls });
    };

    const governanceUploadFields = (): JSX.Element => {
        switch (formFieldState.content_type) {
            case GovernanceContentType.DOCUMENT:
                const fileDragAndDropProps: FileDragAndDropSingleModeProps = {
                    labelText: 'Document',
                    inputId: 'closureEvidence',
                    hideRemoveNewDocumentButton: true,
                    onSelectFile: onSelectFile,
                    onRemoveFile: onRemoveFile,
                    file: (() => {
                        if (file) {
                            return file;
                        } else if (initialEffectiveVersion?.content_type === GovernanceContentType.DOCUMENT) {
                            return new File([''], initialEffectiveVersion.file.filename);
                        } else {
                            return undefined;
                        }
                    })(),
                };

                return (
                    <div className={styles.fileSelectionContainer}>
                        <FileDragAndDropSingleMode {...fileDragAndDropProps} />
                    </div>
                );
            case GovernanceContentType.TEXT:
                return (
                    <div className={styles.fieldContainer}>
                        <FormFieldTextArea required handleChange={handleChange} formFieldId="text" formFieldLabel="Definition" rows={3} tooltip={`The textual definition of the ${governanceTypeText}.`} value={formFieldState.text} />
                    </div>
                );
            case GovernanceContentType.EXTERNAL_URL:
                return (
                    <div className={styles.fieldContainer}>
                        <FormFieldText required handleChange={handleChange} formFieldId="external_url" formFieldLabel="Link" tooltip={`The hyperlink to the definition of the ${governanceTypeText}.`} value={formFieldState.external_url ?? ''} />
                    </div>
                );
        }
    };

    const getBreadcrumb = (): JSX.Element => {
        if (controlNavigatedFrom) {
            const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(controlNavigatedFrom);
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                    <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{controlNavigatedFrom.metadata.control_group_name}</BreadcrumbLink>
                    <BreadcrumbLink link={`${getFrameworkGroupControlURL(controlNavigatedFrom.identifier)}#governance`}>{controlNavigatedFrom.metadata.is_custom ? controlNavigatedFrom.metadata.control_name : controlId}</BreadcrumbLink>
                    <BreadcrumbText>{initialEffectiveVersion?.title ?? 'Add New Version'}</BreadcrumbText>
                </Breadcrumb>
            );
        } else {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${GOVERNANCE}`}>Governance Integration</BreadcrumbLink>
                    {governanceId && initialEffectiveVersion?.title && <BreadcrumbLink link={`/${GOVERNANCE}/${governanceId}`}>{initialEffectiveVersion.title}</BreadcrumbLink>}
                    <BreadcrumbText>Add New Version</BreadcrumbText>
                </Breadcrumb>
            );
        }
    };

    const manageGovernanceLocation = (versionId: string) => `/${GOVERNANCE}/${versionId}`;

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    } else if (controlMappingItems! && initialEffectiveVersion !== undefined) {
        return (
            <>
                {createdVersionId && formState.successMessage && <LinkButtonToast variant="success" clearToast={() => setFormState({ isUpdatingGovernance: false })} linkButtonText={controlNavigatedFrom ? 'Return to control' : 'Manage versions'} linkButtonTo={controlNavigatedFrom ? `${getFrameworkGroupControlURL(controlNavigatedFrom.identifier)}#governance` : manageGovernanceLocation(createdVersionId)} text={formState.successMessage} />}
                {formState.failureMessage && <TextToast clearToast={() => setFormState({ isUpdatingGovernance: false })} text={formState.failureMessage} variant="failure" />}
                <Page
                    headerBreadcrumb={getBreadcrumb()}
                    headerTitle={initialEffectiveVersion ? initialEffectiveVersion.title : 'Add New Version'}
                    body={[
                        {
                            content: (
                                <>
                                    <Form noValidate>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldText required handleChange={handleChange} formFieldId="title" formFieldLabel="Title" tooltip={`The unique name of the ${governanceTypeText}.`} value={formFieldState.title ?? ''} />
                                        </div>
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldUserSelect isRequiredField users={users} onUserSelected={handleSelectOwnerChange} formFieldId="owner" selectedUser={formFieldState.owner} formFieldLabel="Owner" tooltip={`The individual responsible for the ${governanceTypeText}.`} />
                                            </div>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldDatePicker required dateFormat="MM/dd/yyyy" selected={formFieldState.effective_date} handleChange={handleChangeEffectiveDate} formFieldId="effective_date" formFieldLabel="Effective Date" placeholder={'MM/DD/YYYY'} tooltip={`The date on which the ${governanceTypeText} did/will become effective.`} />
                                            </div>
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldSelect isRequiredField options={GovernanceValuesSelectOptions} handleChange={handleSelectChange} formFieldId="type" selectedOption={formFieldState.type} formFieldLabel="Type" tooltip="The type of governance." />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea required formFieldId="changelog" formFieldLabel="Changelog" value={formFieldState.changelog} handleChange={handleChange} />
                                        </div>
                                        <div className={styles.contentContainer}>
                                            <ModalHeader text="Content" />
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldSelect formFieldId="content_type" selectedOption={formFieldState.content_type} options={GovernanceContentTypeSelectOptions} handleChange={handleSelectChange} formFieldLabel="Content Type" isRequiredField tooltip={`The medium by which the ${governanceTypeText} is defined.`} />
                                            </div>
                                            {governanceUploadFields()}
                                        </div>
                                    </Form>
                                    <ModalHeader text={`Map ${governanceTypeText} to Controls`} />
                                    <MultipleControlMapping controls={controlMappingItems} handleControlChange={handleAssociatedControlChange} currentMappedControlIdentifiers={controlIdQueryParam ? [controlIdQueryParam] : initialAssociatedControls} />
                                    <div className={styles.submitButton}>
                                        <Button variant="primary" onClick={submitRequest} fontAwesomeImage={faChevronCircleRight} disabled={createdVersionId !== undefined} isLoading={formState.isUpdatingGovernance} loadingText="Saving...">
                                            SAVE
                                        </Button>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else return <Placeholder />;
};
